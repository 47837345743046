<!-- top bar -->
<div class="top-bar">
  <app-logo></app-logo>
  <button (click)="goToChiamatemi()">
    Chiamatemi
  </button>
</div>
<main>
    <!--#start region left side-->
    <aside>
      <div>
        <p *ngIf="step<4" class="invert">
          Proteggi il tuo migliore amico in pochissimi step!
        </p>
        <div *ngIf="step<4" class="progression-line">
          <div class="row top">
            <div *ngIf="step==1" class="circle"></div>
            <div *ngIf="step==1" class="number">1</div>
            <img *ngIf="step>1" src="../assets/svg/aside-check.svg" alt="Check image">
            <div class="label">Parlaci del tuo Pet</div>
          </div>

          <div class="line"></div>

          <div class="row middle">
            <div *ngIf="step==2" class="circle"></div>
            <div *ngIf="step==2" class="number">2</div>
            <div *ngIf="step==1" class="dot"></div>
            <div class="label">Parlaci di te</div>
          </div>

          <!-- <div class="line"></div> -->

          <!-- <div class="row bottom">
            <div *ngIf="step==3" class="circle"></div>
            <div *ngIf="step==3" class="number">3</div>
            <div *ngIf="step==1 || step==2" class="dot"></div>
            <div class="label">Parlaci di te</div>
          </div> -->
        </div>
      </div>
    </aside>
    <!--#end region left side-->
    <!--#start region form-->
    <div>
      <div class="insurance-form">

        <div class="progression-line" *ngIf="step==1">
          <div class="line-step-1 blue"></div>
          <div class="line-step-1 gray"></div>
        </div>
        <div class="progression-line" *ngIf="step==2">
          <div class="line-step-2 blue"></div>
          <div class="line-step-2 gray"></div>
        </div>

        <div class="check-img">
          <img *ngIf="step==3" src="../assets/svg/check.svg" alt="Check Image">
        </div>

        <div id="step-1" *ngIf="step==1">
          <h2>Parlaci del tuo amico a quattro zampe</h2>
          <p class="p-small">Successivamente per procedere sarà necessario disporre del numero di microchip o tatuaggio</p>

          <label [class.error]="petErrors.specie">Che animale è?<span>*</span></label><br>
          <div class="radios">
            <label *ngFor="let specie of species"
              class="radios-option" [for]="specie.nome"
              (click)="selectSpecies(specie)"
              [class.error]="petErrors.specie"
              [class.selected]="pet.specie === specie.nome"
            >
              <div class="radio-option-label">
                <img [attr.src]="petErrors.specie ? '../assets/svg/'+specie.nome+'-error.svg' : '../assets/svg/'+specie.nome+'.svg'" [alt]="'Immagine '+specie.nome">
                <label [for]="specie.nome">{{specie.nome}}</label>
              </div>
              <input type="radio" [value]="specie.nome" name="tipo" [id]="specie.nome" [checked]="pet.specie === specie.nome">
            </label>
          </div>

          <app-input
            label="Come si chiama?"
            name="nome"
            autocomplete="given-name"
            [required]="true"
            [errorText]="petErrors.name"
            (input)="validatePetName()"
            [(ngModel)]="pet.name"
          >
          </app-input>

          <label [class.error]="petErrors.gender">Di che sesso è?<span>*</span></label><br>
          <div class="radios">
            <label *ngFor="let gender of genders"
              class="radios-option" [for]="gender.nome"
              (click)="selectGender(gender)"
              [class.error]="petErrors.gender"
              [class.selected]="pet.gender === gender.value"
            >
              <div class="radio-option-label">
                <label [for]="gender.nome">{{gender.nome}}</label>
              </div>
              <input type="radio" [value]="gender.value" name="sesso" [id]="gender.nome" [checked]="pet.gender === gender.value">
            </label>
          </div>

          <app-select
            label="Quanti anni ha?"
            name="anni"
            [required]="true"
            [errorText]="petErrors.age"
            [options]="petAges"
            (change)="validateAge()"
            [(ngModel)]="pet.age">
          </app-select>

          <label [class.error]="petErrors.macroRace">Di che razza è?<span>*</span></label><br>
          <div class="radios">
            <label
              class="radios-option" for="meticcio"
              (click)="selectMacroRace('meticcio')"
              [class.selected]="pet.macroRace === 'meticcio'"
              [class.error]="petErrors.macroRace"
            >
              <div class="radio-option-label">
                <label for="meticcio">Meticcio</label>
              </div>
              <input [disabled]="pet.specie==''" type="radio" value="meticcio" name="razza" id="meticcio" [checked]="pet.macroRace === 'meticcio'">
            </label>

            <label
              class="radios-option" for="di razza"
              (click)="selectMacroRace('di razza')"
              [class.selected]="pet.macroRace === 'di razza'"
              [class.error]="petErrors.macroRace"
            >
              <div class="radio-option-label">
                <label for="di razza">Di razza</label>
              </div>
              <input [disabled]="pet.specie==''" type="radio" value="di razza" name="razza" id="di razza" [checked]="pet.macroRace === 'di razza'">
            </label>
          </div>

          <ng-container *ngIf="pet.macroRace==='di razza' && pet.specie">
            <app-select
              label="Seleziona razza"
              name="razza"
              [required]="true"
              [errorText]="petErrors.race"
              [options]="races"
              (change)="selectRace()"
              [(ngModel)]="pet.race.value"
              >
            </app-select>
          </ng-container>

          <div *ngIf="pet.macroRace==='meticcio' && pet.specie ==='Cane'">
            <label [class.error]="petErrors.size">Di che taglia è?<span>*</span></label><br>
            <div class="sizes">
              <label
                *ngFor="let size of sizes"
                class="size-option"
                (click)="selectSize(size)"
                [class.selected]="pet.size === size.idTaglia"
                [class.error]="petErrors.size"
              >
                <h4>{{size.nome}}</h4>
                <label>{{size.pesoMinimo}} - {{size.pesoMassimo}} kg</label>
              </label>
            </div>
          </div>
        </div>

        <!-- <div id="step-2" *ngIf="step==2">
          <h2>Che tipo di rimborso vorresti</h2>
          <div class="refunds">
            <ng-container *ngFor="let insurance of insurances">
              <label
              *ngIf="insurance.nomeSpecie === pet.specie"
              class="refund-option"
              (click)="selectInsurance(insurance.idAssicurazione)"
              [class.selected]="pet.insurance.idAssicurazione === insurance.idAssicurazione"
              [class.error]="petErrors.insurance"
            >
              <h4>{{ insurance.percento }}</h4>
              <label>{{ insurance.descrizioneRegistrazione}}</label>
            </label>
            </ng-container>
          </div>
        </div> -->

        <div id="step-3" *ngIf="step==2">
          <h2>Parlaci di te</h2>

          <app-input
            placeholder="Inserisci nome..."
            label="Nome"
            name="nome"
            autocomplete="given-name"
            [required]="true"
            [errorText]="accountErrors.name"
            (input)="validateName()"
            [(ngModel)]="account.name"
          ></app-input>
          <app-input
            placeholder="Inserisci cognome..."
            label="Cognome"
            name="cognome"
            autocomplete="family-name"
            [required]="true"
            [errorText]="accountErrors.surname"
            (input)="validateSurname()"
            [(ngModel)]="account.surname"
          ></app-input>

          <app-input
            type="email"
            placeholder="nome@gmail.com"
            label="E-mail"
            name="email"
            autocomplete="email"
            [required]="true"
            [errorText]="accountErrors.email"
            (input)="validateEmail()"
            [(ngModel)]="account.email"
          ></app-input>

          <app-input
            placeholder="322 348 4477"
            label="Telefono (falcoltativo)"
            name="telefono"
            [required]="false"
            [errorText]="accountErrors.telephone"
            (input)="validateTelephone()"
            [(ngModel)]="account.telephone"
          ></app-input>

          <app-toggle
            [(ngModel)]="account.newsletter"
            name="newsletter"
            label="Vorrei restare aggiornato sulle novità e le offerte MyVet"
          ></app-toggle>
        </div>

        <div class="buttons-section">
          <button *ngIf="step>1 && step<3" (click)="backStep()">Indietro</button>
          <button (click)="nextStep()">Continua</button>
        </div>
      </div>

    </div>
    <!--#end region form-->
  </main>


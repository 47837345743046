<main>
    <h2>Sezione Reclami</h2>
    
    <p>
        I clienti di Benelli Assicurazioni non soddisfatti del servizio ricevuto possono formulare i loro reclami riguardanti il rapporto contrattuale indirizzando a:
        <br>
        <br>
        <span class="center">
            <a href="mailto:info@benelliassicurazioni.it">info&#64;benelliassicurazioni.it</a><br>
            oppure a <a href="mailto:benelliassicurazioni@legalmail.it">benelliassicurazioni&#64;legalmail.it</a> (posta elettronica certificata)<br>
        </span>
        <br>

        riferendo le seguenti informazioni:
        <br>
        <br>
        &bull; nome, cognome, indirizzo e recapito telefonico del reclamante<br>
        &bull; numero della polizza e/o nominativo del contraente al quale il reclamo fa riferimento<br>
        &bull; numero e/o data del sinistro al quale il reclamo fa riferimento<br>
        &bull; eventuale indicazione del soggetto o dei soggetti dei quali si lamenta l’operato<br>
        &bull; descrizione del motivo del reclamo<br><br>
        In alternativa all’uso dell’ e-mail è possibile scrivere al seguente indirizzo:<br>
        <br>
        <span class="center">
            BENELLI ASSICURAZIONI SRL<br>
            Via Rosselli del Turco, 30<br>
            47122 Forlì (FC)<br>
        </span>
        <br>
        Il responsabile della funzione incaricata della gestione dei reclami è Luigi Benelli, rappresentante legale di Benelli Assicurazioni Srl.
        Sarà cura della Società rispondere al reclamante entro il termine massimo di 30 giorni dalla data di ricevimento del reclamo.
        Qualora il reclamante non si ritenga soddisfatto della risposta ricevuta potrà rivolgersi ai seguenti indirizzi, allegando la documentazione relativa al reclamo trattato dall'intermediario:
        <br><br>
        <span class="center">
            IVASS – Istituto di vigilanza sulle Assicurazioni - Servizio Vigilanza Intermediari<br>
            Via del Quirinale, 21<br>
            00187 Roma<br>
            fax: 06/42133206<br>
            pec: <a href="mailto:ivass@pec.ivass.it">ivass&#64;pec.ivass.it</a><br>
        </span>
    </p>
</main>
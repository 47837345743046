  <!--chiamatemi-->
  <div id="chiamatemi">
    <h2>
      Prendi un appuntamento telefonico gratuito con i nostri consulenti!
    </h2>
    <p>
      Compila il modulo sottostante: un consulente MyVet ti contatterà per
      rispondere a tutte le tue domande.
    </p>
    <div class="middle">
      <div class="row">
        <div class="column">
          <div>
            <button [class.selected]="!dummy1" (click)="dummy1 = false">
              Signor
            </button>
            <button [class.selected]="dummy1" (click)="dummy1 = true">
              Signora
            </button>
          </div>
          <app-input
            placeholder="Inserisci nome..."
            label="Nome"
            name="nome"
            autocomplete="given-name"
            [errorText]="callRequestErrors.name"
            (input)="validateName()"
            [(ngModel)]="callRequest.name"
          ></app-input>
          <app-input
            placeholder="Inserisci cognome..."
            label="Cognome"
            name="cognome"
            autocomplete="family-name"
            [errorText]="callRequestErrors.surname"
            (input)="validateSurname()"
            [(ngModel)]="callRequest.surname"
          ></app-input>
          <app-input
            placeholder="322 348 4477"
            label="Telefono"
            name="telefono"
            [errorText]="callRequestErrors.tel"
            (input)="validateTelephone()"
            [(ngModel)]="callRequest.tel"
          ></app-input>
        </div>
        <div class="column">
          <div>
            <button [class.selected]="!dummy2" (click)="dummy2 = false">
              Scegli una data
            </button>
            <button [class.selected]="dummy2" class="dataButton" (click)="dummy2 = true">
              Chiamatemi ora
            </button>
          </div>
          <app-input
            *ngIf="!dummy2"
            placeholder="11/10/1997"
            label="Data della chiamata"
            name="data"
            [type]="'date'"
            [errorText]="callRequestErrors.date"
            (input)="validateDate()"
            [(ngModel)]="callRequest.date"
          ></app-input>
          <app-input
            *ngIf="!dummy2"
            label="Ora della chiamata"
            name="ora"
            [type]="'time'"
            [errorText]="callRequestErrors.time"
            [(ngModel)]="callRequest.time"
          ></app-input>
        </div>
      </div>
      <div class="row">
        <div class="footer-request">
          <p>
            Le informazioni raccolte sono necessarie per
            l'invio di una proposta di preventivo personalizzato e per la
            conclusione, la gestione e l'esecuzione del contratto. Per
            saperne di più sulla gestione dei tuoi dati personali e per
            esercitare i tuoi diritti, consulta la nostra informativa sulla
            <a routerLink="/tos">informativa sulla privacy.</a>
            <br />
          </p> 
          <button class="send-request" (click)="sendContactRequest($event)">
            Invia richiesta
          </button>
        </div>
      </div>
    </div>

    <h2 class="h2-nd">Perché tutelare la salute del tuo animale?</h2>
    <div class="packs">
      <label class="insurance-option">
        <label class="description">
          <img [attr.src]="'../assets/svg/classic-check.svg'" />
        </label>
        <div class="price">
          <label
            >Accedi più facilmente alle migliori cure veterinarie</label
          >
        </div>
      </label>
      <label class="insurance-option">
        <label class="description">
          <img [attr.src]="'../assets/svg/classic-check.svg'" />
        </label>
        <div class="price">
          <label>Proteggi il tuo budget dagli imprevisti</label>
        </div>
      </label>
      <label class="insurance-option">
        <label class="description">
          <img [attr.src]="'../assets/svg/classic-check.svg'" />
        </label>
        <div class="price">
          <label
            >Mantieni il tuo animale in buona salute, per tutta la
            vita</label
          >
        </div>
      </label>
    </div>
    <app-dialog 
      #modalConfirm
      title="Richiesta inviata con successo!"
      [autoclose]="true" 
      [success]="true"    
    >
    </app-dialog>
    <app-dialog 
      #modalError
      title="Qualcosa è andato storto, riprova più tardi!"
      [autoclose]="true" 
      [error]="true"
  >
  </app-dialog>
  </div>



<nav>
  <div class="logo">
    <app-logo></app-logo>
    <label
      >sceglie <em>Benelli Assicurazioni</em> <br />
      per il Tuo amico a quattro zampe
    </label>
  </div>

  <div>
    <button
      routerLink="/registration/1"
      *ngIf="!router.url.startsWith('/registration')"
    >
      Proteggi il tuo Pet
    </button>

    <!-- <input id="menu-toggle" type="checkbox" />
    <label class="menu-button-container" for="menu-toggle">
      <div class="menu-button"></div>
    </label> -->

    <!-- <ul class="menu" (click)="navbarPicked()">
      <li>
        <a> Chi Siamo </a>
      </li>
      <li routerLink="/faqs">
        <a> FAQ </a>
      </li>
      <li routerLink="/chiamatemi"><a>Contatti</a></li>
      <li (click)="redirectToPage()">
        <a>
          <img src="../../../assets/svg/profile.svg" alt="" />
        </a>
      </li>
    </ul> -->

    <button
      *ngIf="!isOnChiamatemiPage()"
      class="empty"
      routerLink="/chiamatemi"
    >
      Chiamatemi
    </button>
    <button *ngIf="isOnChiamatemiPage()" (click)="goBack()">Indietro</button>
  </div>
</nav>

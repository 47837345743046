<svg
  viewBox="0 0 111 47"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  routerLink="/home"
  [class]="size"
>
  <path
    d="M26.0628 3.5018C27.0997 5.77485 27.2803 7.98125 26.273 10.1763C24.7535 13.4902 21.1037 14.1963 18.4922 11.661C16.1714 9.40918 15.6395 6.62565 16.5664 3.59256C17.1421 1.71087 18.4259 0.397811 20.4222 0.0674175C22.4891 -0.27432 24.0523 0.718278 25.3023 2.27807C25.6226 2.67937 25.8511 3.15581 26.0628 3.5018Z"
    fill="currentColor"
  />
  <path
    d="M4.93433 14.3807C3.17361 10.8087 4.24725 7.3701 7.25938 6.48527C10.0373 5.66992 12.2467 6.8582 14.0201 8.88168C15.7766 10.8867 16.4848 13.2335 15.4084 15.8299C14.686 17.574 12.9295 18.5694 10.9586 18.4319C8.05791 18.2334 6.13636 16.5771 4.93433 14.3807Z"
    fill="currentColor"
  />
  <path
    d="M9.97659 22.1385C11.2463 24.637 9.93427 27.2717 7.09144 27.7779C4.57451 28.226 2.45121 27.4206 0.86825 25.3843C-0.740101 23.3126 -0.0360948 20.5745 2.33693 19.4727C4.9893 18.2433 8.64618 19.5195 9.97659 22.1385Z"
    fill="currentColor"
  />
  <path
    d="M34.1136 14.4246C32.1398 15.3945 30.1167 14.6827 29.1742 12.6876C27.9059 10.0019 29.2377 6.34206 31.9282 5.12258C33.8878 4.2335 35.9731 5.0531 36.8224 7.04538C37.944 9.6786 36.6615 13.174 34.1136 14.4246Z"
    fill="currentColor"
  />
  <path
    d="M38.9441 19.7605L34.6255 19.769L28.6464 34.8537L22.6433 19.7903L18.377 19.7988L18.415 40.0081L22.6786 39.9996L22.6673 34.3475C22.6645 32.134 22.6348 30.4409 22.388 28.9392L26.6473 39.9925L30.6696 39.984L34.9148 28.918C34.6989 30.4196 34.7017 31.3753 34.7102 35.7442L34.7158 39.9783L38.9822 39.9698L38.9441 19.7605Z"
    fill="currentColor"
  />
  <path
    d="M51.914 26.2904L48.6436 35.0621L45.6668 26.3032L41.1846 26.3117L46.6713 40.4193L46.4258 41.0744C45.8869 42.552 45.2901 42.9902 43.8764 42.993L41.5669 42.9958L41.5725 46.5181C42.388 46.762 43.3417 46.8967 44.3448 46.8967C46.7348 46.8911 48.2571 45.7141 49.3928 42.9278L56.2636 26.2819L51.914 26.2904Z"
    fill="currentColor"
  />
  <path
    d="M70.4329 19.7024L66.7013 29.9247C66.2681 31.1258 65.8914 32.246 65.3765 34.1858C64.9377 32.4374 64.5554 31.1825 64.0926 29.9276L60.2693 19.7208L55.5684 19.7322L63.1304 39.9244L67.6691 39.9159L75.0209 19.6939L70.4329 19.7024Z"
    fill="currentColor"
  />
  <path
    d="M83.8259 35.2706C83.5296 36.2561 82.4179 36.8318 80.8406 36.8346C78.9938 36.8375 77.9864 35.9938 77.7395 34.1915L87.6013 34.1178V33.0514C87.5928 28.6003 84.9249 25.7913 80.6586 25.7998C76.5559 25.8083 73.7074 28.7889 73.7158 33.0514C73.7243 37.2558 76.6631 40.2534 80.7658 40.2463C84.489 40.2407 87.1752 38.2952 87.6605 35.2649L83.8259 35.2706ZM80.6924 29.2143C82.3755 29.2115 83.4379 30.1374 83.4407 31.5852L77.7875 31.5966C78.1106 29.9559 79.0347 29.2172 80.6924 29.2143Z"
    fill="currentColor"
  />
  <path
    d="M98.0007 29.7021L97.9923 26.2067L95.4105 26.2124L95.402 21.9783L91.2189 21.9868L91.2273 26.2181L88.6455 26.2238L88.654 29.7191L91.233 29.7134L91.2513 39.8734L95.4373 39.8649L95.4189 29.7049L98.0007 29.7021Z"
    fill="currentColor"
  />
</svg>

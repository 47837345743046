// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  apiVersion: 'v1.0',
  apiUrl: 'https://devinsure.myvetbusiness.it/',

  pkStripe:
    'pk_test_51OZTDGEsFSxa9rAmNNMjM9DE5UCB48iKUrIEffTJnnsU3ELGQPvqI7Of2cm7HfXs5bKSuzauFtNttym54Us0Br6T007RdsnR5z',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

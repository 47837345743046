import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { FaqComponent } from '../components/faq/faq.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-showcase',
  standalone: true,
  templateUrl: './showcase.component.html',
  styleUrl: './showcase.component.scss',
  imports: [FaqComponent, CommonModule, RouterModule],
})
export class ShowcaseComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}

  type: string = '';
  questions: any[] = [
    {
      question: `Posso andare dal mio veterinario di fiducia?`,
      answer: `Certo, potrai scegliere il tuo veterinario di fiducia per far curare il tuo pet e ti rimborseremo l'importo che hai speso in base al pacchetto scelto.`,
    },
    {
      question: `Che cosa copre un piano di protezione sanitaria per animali domestici?`,
      answer: `Un piano di tutela sanitaria per il tuo amico a quattro zampe offre una copertura completa per sostenerti finanziariamente in una varietà di situazioni mediche. Questo include:<br><br>
      <strong>Spese veterinarie connesse ad interventi chirurgici</strong>: Se il tuo animale necessita di un'operazione chirurgica, sia essa d'emergenza o programmata, il piano si preoccupa di coprire i costi associati. Questo può comprendere l'operazione stessa, l'anestesia, il monitoraggio durante l'intervento e le cure post-operatorie<br><br>
      <strong>Spese veterinarie non connesse ad interventi chirurgici</strong>: La tutela include anche le visite veterinarie di routine o quelle per condizioni non chirurgiche, come le diagnosi e i trattamenti per varie malattie, le visite di controllo e le cure mediche generali.<br><br>
      <strong>Spese di prevenzione</strong>: Per assicurare il mantenimento della salute e del benessere del tuo animale, il piano copre anche le spese preventive. Questo include vaccinazioni, controlli periodici, trattamenti antiparassitari e altri servizi volti a prevenire l'insorgere di malattie e problemi di salute.<br><br>
      Optando per un piano di protezione sanitaria, assicuri che il tuo animale riceva le cure necessarie senza doverti preoccupare dell'impatto finanziario. Questo contribuisce a garantire una vita lunga, sana e felice per il tuo amato compagno.`,
    },
    {
      question: `Perché è vantaggioso proteggere il tuo amico a quattro zampe?`,
      answer: `Sottoscrivere un piano di salvaguardia sanitaria per il tuo animale domestico offre numerosi vantaggi che contribuiscono al benessere del tuo fedele compagno e alla tua tranquillità finanziaria:<br><br>
      <strong>Accesso a cure di qualità</strong>: Con un piano di protezione, puoi accedere a un'ampia rete di professionisti veterinari e strutture specializzate, assicurando al tuo animale le migliori cure disponibili senza dover compromettere a causa di limitazioni economiche.<br><br>
      <strong>Gestione delle spese impreviste</strong>: Anche con la migliore prevenzione, gli animali domestici possono incorrere in malattie o incidenti inaspettati che comportano costi veterinari elevati. Un piano di copertura sanitaria aiuta a mitigare questi costi, permettendoti di concentrarti sulla guarigione del tuo animale senza l'aggiuntiva preoccupazione finanziaria.<br><br>
      <strong>Pace mentale</strong>: Sapere di avere un supporto finanziario in caso di necessità ti offre tranquillità, permettendoti di godere appieno della compagnia del tuo amico. La certezza di poter affrontare eventuali spese mediche future senza stress contribuisce significativamente al tuo benessere emotivo e a quello del tuo Pet.<br><br>
      <strong>Flessibilità e personalizzazione</strong>: I piani di protezione sanitaria per animali sono spesso flessibili e possono essere personalizzati per adattarsi alle specifiche esigenze del tuo animale e alla tua situazione finanziaria, garantendo che abbiate esattamente il livello di copertura necessario.<br><br>
      In conclusione, un piano di salvaguardia sanitaria rappresenta un investimento nella salute e nella felicità del tuo animale domestico, offrendo protezione contro l'incertezza e assicurando che le cure necessarie siano sempre a portata di mano.`,
    },
    {
      question: `Chi è Benelli Assicurazioni x MyVet?`,
      answer: `La storia di Benelli Assicurazioni nasce nel lontano Agosto 1913 quando Domenico Benelli riceve l'incarico di agente generale per la provinca di Forlì dall'Istituto Nazionale delle Assicurazioni (INA). Oggi Benelli Assicurazioni mette a disposizione della propria clientela una consolidata esperienza e competenza professionale, credendo fortemente alle collaborazioni con nuove giovani realtà. Per questo ha deciso di collaborare con MyVet per creare un servizio che possa tutelare la salute dei nostri amici a quattro zampe. `,
    },
    {
      question: `Cos'è il mondo MyVet?`,
      answer: `MyVet è un'innovativa azienda nel settore del benessere e della protezione degli animali domestici, che va ben oltre la mera offerta di piani di salvaguardia sanitaria. La nostra missione è di fornire un supporto completo per ogni aspetto della vita del vostro animale domestico, garantendo non solo assistenza finanziaria per le necessità mediche ma anche strumenti e risorse per migliorare la qualità della loro vita quotidiana. <br><br>
      <strong>Soluzioni Tecnologiche Avanzate</strong>: MyVet sviluppa e implementa software all'avanguardia nel campo veterinario, inclusi sistemi di gestione per i professionisti e applicazioni mobili che fungono da libretti sanitari elettronici per i proprietari di animali. Queste soluzioni mirano a semplificare la gestione delle informazioni sanitarie e a migliorare l'accesso alle cure.<br><br>
      <strong>Piattaforme Interattive</strong>: Offriamo siti web e applicazioni che facilitano la comunicazione e l'interazione tra veterinari, proprietari di animali e fornitori di servizi, creando una comunità dedicata al benessere animale.<br><br>
      <strong>Programmi di Tutela Sanitaria</strong>: Forniamo programmi di protezione sanitaria che coprono una vasta gamma di necessità mediche per i vostri animali, dalle spese preventive e di routine fino alle cure per condizioni più gravi, assicurando che possiate offrire al vostro animale le cure necessarie in ogni momento della sua vita.<br><br>
      <strong>Supporto e Educazione</strong>: MyVet si impegna anche a educare e supportare i proprietari di animali domestici, fornendo loro le informazioni e le risorse necessarie per prendersi cura al meglio dei loro compagni.<br><br>
      In sintesi, MyVet rappresenta un partner affidabile e completo per la salute e il benessere dei vostri animali domestici, offrendo non solo sostegno finanziario attraverso i nostri programmi di protezione sanitaria, ma anche un'ampia gamma di servizi e soluzioni tecnologiche per migliorare la vita di animali e proprietari.`,
    },
  ];

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.type = params['type'] ?? 'cani';
      console.log(this.type);
    });
  }
}

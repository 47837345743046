import { RouterModule, Router } from '@angular/router';
import { Component } from '@angular/core';
import { InputComponent } from '../components/input/input.component';
import { SelectComponent } from '../components/select/select.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ToggleComponent } from '../components/toggle/toggle.component';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    InputComponent,
    RouterModule,
    SelectComponent,
    FormsModule,
    CommonModule,
    ToggleComponent,
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  tren: any = '';

  login: any = { email: '', password: '', tipologia: 'Utente' };
  loginErrors: any = { email: '', password: '' };

  constructor(private router: Router) {}

  ngOnInit() {
    this.login = { username: '', password: '' };
    this.loginErrors = { username: '', password: '' };
  }

  tryLogIn() {
    if (this.loginErrors.username == '' && this.loginErrors.password == '') {
    }
  }
}

import { CommonModule } from '@angular/common';
import { Component, Input, forwardRef, HostListener } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-toggle',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleComponent),
      multi: true,
    },
  ],
})
export class ToggleComponent implements ControlValueAccessor {
  @Input() name: string = '';
  @Input() label: string = '';
  @Input() required: boolean = false;
  @Input() error: boolean = false;
  checked = false;
  //to expand the label of the toggle on mobile screens
  expanded: boolean = false;

  onChange: any = () => {};
  onTouch: any = () => {};

  constructor(private router: Router) {}

  //per i link dentro alla label del toggle
  @HostListener("click", ["$event"])
  handle(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (target.nodeName === "A") {
      event.preventDefault();
      event.stopPropagation();
      const routerLink = target.getAttribute("href");
      //perchè vanno aperti in una pagina diversa da quella dove è presente il componente
      const url = this.router.createUrlTree([routerLink])
      window.open(url.toString(), '_blank')
    }
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.checked = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  toggleChecked(): void {
    this.checked = !this.checked;
    this.onChange(this.checked);
    this.onTouch();
  }

  toggleExpansion() {
    this.expanded = !this.expanded;
    console.log(this.expanded)
  }
}

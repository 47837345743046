import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dialog',
  standalone: true,
  imports: [ CommonModule, ],
  animations: [],
  templateUrl: './dialog.component.html',
  styleUrl: './dialog.component.scss'
})
export class DialogComponent {

  @Input() title: string = '';
  @Input() text: string = '';
  @Input() cancelButton: boolean = false;
  @Input() confirmButton: boolean = false;
  @Input() autoclose: boolean = false;

  //tipi di modal
  @Input() error: boolean = false; 
  @Input() success: boolean = false;

  @ViewChild('dialog', { static: false }) dialog!: ElementRef;

  @Output() emitResponse = new EventEmitter<Boolean>();

  async showModal() {
    this.dialog.nativeElement.showModal();
    if(this.autoclose){
      setTimeout(() => {
        this.closeModal();
      }, 1000);
    }
  }

  closeModal() {
    this.dialog.nativeElement.close();
  }

  confirmMod() {
    this.emitResponse.emit(true);
    this.closeModal();
  }
}

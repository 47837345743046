import { Component, OnInit } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { LogoComponent } from '../logo/logo.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-navbar',
  standalone: true,
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  imports: [LogoComponent, RouterModule, CommonModule],
})
export class NavbarComponent implements OnInit {
  token: string = '';

  constructor(public router: Router) {}

  ngOnInit() {
    this.token = localStorage.getItem('jwt-myvetassicurazioni') ?? '';
  }

  redirectToPage() {
    const token = localStorage.getItem('jwt-myvetassicurazioni');

    if (token) {
      this.router.navigate(['/profile']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  navbarPicked() {
    const checkbox = document.getElementById('menu-toggle') as HTMLInputElement;
    checkbox.checked = false;
  }

  isOnChiamatemiPage(): boolean {
    return this.router.url.includes('/chiamatemi');
  }

  goBack(): void {
    window.history.back();
  }
}

<div [class]="errorText ? 'input error' : 'input '">
  <div class="header">
    <input
      *ngIf="type != 'textarea'"
      [attr.type]="type == 'password' ? 'text' : type"
      [attr.id]="name + '-text-field'"
      [attr.name]="name"
      [attr.placeholder]="placeholder"
      [attr.autocomplete]="autocomplete"
      [attr.autofocus]="autofocus ? true : null"
      [attr.readonly]="readonly ? true : null"
      [attr.required]="required"
      [attr.min]="min"
      [attr.max]="max"
      [attr.minlength]="minlength"
      [attr.maxlength]="maxlength"
      [attr.aria-describedby]="name + '-description'"
      [attr.aria-invalid]="errorText ? 'true' : 'false'"
      [class]="
        type == 'password'
          ? showPassword
            ? 'show-text'
            : 'hide-pass'
          : 'show-text'
      "
      [(ngModel)]="value"
      (blur)="onBlur()"
    />
    <div
      class="input-button"
      *ngIf="type == 'password'"
      (click)="togglePasswordVisibility()"
    >
      <svg
        *ngIf="showPassword"
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-eye-filled"
        width="44"
        height="44"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#2c3e50"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path
          d="M12 4c4.29 0 7.863 2.429 10.665 7.154l.22 .379l.045 .1l.03 .083l.014 .055l.014 .082l.011 .1v.11l-.014 .111a.992 .992 0 0 1 -.026 .11l-.039 .108l-.036 .075l-.016 .03c-2.764 4.836 -6.3 7.38 -10.555 7.499l-.313 .004c-4.396 0 -8.037 -2.549 -10.868 -7.504a1 1 0 0 1 0 -.992c2.831 -4.955 6.472 -7.504 10.868 -7.504zm0 5a3 3 0 1 0 0 6a3 3 0 0 0 0 -6z"
          stroke-width="0"
          fill="currentColor"
        />
      </svg>

      <svg
        *ngIf="!showPassword"
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-eye-closed"
        width="44"
        height="44"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#2c3e50"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M21 9c-2.4 2.667 -5.4 4 -9 4c-3.6 0 -6.6 -1.333 -9 -4" />
        <path d="M3 15l2.5 -3.8" />
        <path d="M21 14.976l-2.492 -3.776" />
        <path d="M9 17l.5 -4" />
        <path d="M15 17l-.5 -4" />
      </svg>
    </div>

    <textarea
      *ngIf="type == 'textarea'"
      rows="7"
      [attr.id]="name + '-text-field'"
      [attr.name]="name"
      [attr.placeholder]="placeholder"
      [attr.autocomplete]="autocomplete"
      [attr.autofocus]="autofocus ? true : null"
      [attr.readonly]="readonly ? true : null"
      [attr.required]="required"
      [attr.min]="min"
      [attr.max]="max"
      [attr.minlength]="minlength"
      [attr.maxlength]="maxlength"
      [attr.aria-describedby]="name + '-description'"
      [attr.aria-invalid]="errorText ? 'true' : 'false'"
      [(ngModel)]="value"
      (blur)="onBlur()"
    ></textarea>
    <label [for]="name">{{ label }}<span *ngIf="required">*</span></label>
  </div>
  <div class="footer">
    <div class="text">
      <small [id]="name + '-description'" aria-live="polite" *ngIf="!errorText">
        {{ helperText }}
      </small>
      <small [id]="name + '-description'" aria-live="polite" *ngIf="errorText">
        {{ errorText }}
      </small>
    </div>
    <div class="counter" *ngIf="maxlength">
      <small>{{ characterCount }}/{{ maxlength }}</small>
    </div>
  </div>
</div>

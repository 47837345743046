<main>
    <h2>Privacy & Cookies</h2>
    <h3>Titolare del Trattamento dei Dati</h3>
    <p>
        Il titolare del trattamento è Benelli Assicurazioni s.r.l con sede in Via Rosselli del Turco, 30 47122 Forlì (FC) nella persona del legale rappresentante Luigi Benelli. Contatti: E-mail <a href="mailto:info@benelliassicurazioni.it">info&#64;benelliassicurazioni.it</a>, PEC <a href="mailto:benelliassicurazioni@legalmail.it">benelliassicurazioni&#64;legalmail.it</a>
    </p>
    <h3>Tipologie di Dati raccolti</h3>
    <p>
        Fra i Dati Personali raccolti da questo sito, in modo autonomo o tramite terze parti, ci sono: Cognome, Nome, Email, Cookie e Dati di utilizzo.
        I Dati Personali possono essere inseriti volontariamente dall’Utente.
        L’eventuale utilizzo di Cookie - o di altri strumenti di tracciamento - non consente l'identificazione del’Utente i cui dati sono tracciati unicamente in forma anonima ed elaborati in forma aggregata.
        Il mancato conferimento da parte dell’Utente di alcuni Dati Personali potrebbe impedire a questo sito di erogare i propri servizi.
    </p>
    <h3>Modalità e luogo del trattamento dei Dati raccolti</h3>
    <h4>Modalità di trattamento</h4>
    <p>
        Il Titolare tratta i Dati Personali degli Utenti adottando le opportune misure di sicurezza volte ad impedire l’accesso, la divulgazione, la modifica o la distruzione non autorizzate dei Dati Personali.
        Il trattamento viene effettuato mediante strumenti informatici e/o telematici, con modalità organizzative e con logiche strettamente correlate alle finalità indicate. Oltre al Titolare, in alcuni casi, potrebbero avere accesso ai Dati categorie di incaricati coinvolti nell’organizzazione del sito (personale amministrativo, commerciale, marketing, legali, amministratori di sistema) ovvero soggetti esterni (come fornitori di servizi tecnici terzi, corrieri postali, hosting provider, società informatiche, agenzie di comunicazione) nominati anche, se necessario, Responsabili del Trattamento da parte del Titolare. L’elenco aggiornato dei Responsabili potrà sempre essere richiesto al Titolare del Trattamento.
    </p>
    <h4>Luogo</h4>
    <p>
        I Dati sono trattati presso le sedi operative del Titolare ed in ogni altro luogo in cui le parti coinvolte nel trattamento siano localizzate. Per ulteriori informazioni, contatta il Titolare tramite i recapiti indicati nella presente policy.
    </p>
    <h4>Tempi</h4>
    <p>
        I Dati sono trattati per il tempo necessario allo svolgimento del servizio richiesto dall’Utente, o richiesto dalle finalità descritte in questo documento, e l’Utente può sempre chiedere l’interruzione del Trattamento o la cancellazione dei Dati.
    </p>
    <h3>Finalità del Trattamento dei Dati raccolti</h3>
    <p>
        I Dati dell’Utente sono raccolti per consentire al Titolare di fornire i propri servizi, così come per le seguenti finalità: Contattare l'Utente, Statistica, Interazione con social network e piattaforme esterne, Funzionalità sociali e Visualizzazione di contenuti da piattaforme esterne.
    </p>
    <h3>Cookie Policy</h3>
    <h3>Che cos'è un cookie?</h3>
    <p>
        I cookie sono porzioni di informazioni che il sito Web inserisce nel tuo dispositivo di navigazione quando visiti una pagina. Possono comportare la trasmissione di informazioni tra il sito Web e il tuo dispositivo, e tra quest’ultimo e altri siti che operano per conto nostro o in privato, conformemente a quanto stabilito nella rispettiva Informativa sulla privacy. Possiamo utilizzare i cookie per riunire le informazioni che raccogliamo su di te. Puoi decidere di ricevere un avviso ogni volta che viene inviato un cookie o di disabilitare tutti i cookie modificando le impostazioni del browser. Disabilitando i cookie, tuttavia, alcuni dei nostri servizi potrebbero non funzionare correttamente e non potrai accedere a numerose funzionalità pensate per ottimizzare la tua esperienza di navigazione nel sito. Per maggiori informazioni sulla gestione o la disabilitazione dei cookie del browser, consulta l’ultima sezione della presente Politica sui cookie.
        <br>
        Utilizziamo varie tipologie di cookie con funzioni diverse.
    </p>
    <h4>1. Cookie strettamente necessari</h4>
    <p>
        Questi cookie sono fondamentali per poter navigare sul sito Web e utilizzare alcune funzionalità di base.
        <br>
        Non è necessario fornire il consenso per i cookie strettamente necessari, poiché sono indispensabili per assicurarti i servizi richiesti.
    </p>
    <h4>Cookie per le prestazioni</h4>
    <p>
        È possibile che da parte nostra (o dei i fornitori di servizi che operano per nostro conto) vengano inseriti dei cookie per le prestazioni nel tuo dispositivo di navigazione. Le informazioni raccolte mediante i cookie per le prestazioni sono utilizzate esclusivamente da noi o nel nostro interesse e non consentono di identificare l’utente poiché raccolgono unicamente informazioni anonime relative al modo in cui gli utenti utilizzano il sito Web e le sue varie funzionalità.
    </p>
    <h4>Cookie per le funzionalità</h4>
    <p>
        Questi cookie sono utilizzati per memorizzare le scelte che effettui (preferenza della lingua, paese o altre impostazioni online) e per fornirti le funzionalità personalizzate od ottimizzate che hai selezionato. I cookie per funzionalità possono essere utilizzati per offrirti servizi online o per evitare che ti vengano offerti servizi che hai rifiutato in passato.
    </p>
    <h4>Servizi utilizzati in questo sito (anche di terze parti)</h4>
    <h4>Modulo di contatto (Fox Contact)</h4>
    <p>
        L’Utente, compilando con i propri Dati il modulo di contatto, acconsente al loro utilizzo per rispondere alle richieste di informazioni, di preventivo, o di qualunque altra natura indicata dall’intestazione del modulo.
        <br>
        Dati personali raccolti: Nome, Cognome, Email, Numero di telefono, Settore professionale e contenuti delle richieste.
    </p>
    <h4>Google Analytics (Google)</h4>
    <p>
        Google Analytics è un servizio di analisi web fornito da Google Inc. (“Google”). Google utilizza i Dati Personali raccolti allo scopo di tracciare ed esaminare l’utilizzo di questo sito, compilare report e condividerli con gli altri servizi sviluppati da Google.
        <br>
        Dati personali raccolti: Cookie e Dati di utilizzo. Luogo del trattamento : USA – Privacy Policy – Opt Out
    </p>
    <h4>Abilitazione/disabilitazione di cookie tramite il browser</h4>
    <p>
        Esistono diversi modi per gestire i cookie e altre tecnologie di tracciabilità. Modificando le impostazioni del browser, puoi accettare o rifiutare i cookie o decidere di ricevere un messaggio di avviso prima di accettare un cookie dai siti Web visitati. Ti ricordiamo che disabilitando completamente i cookie nel browser potresti non essere in grado di utilizzare tutte le nostre funzionalità interattive.
        <br>
        Se utilizzi più computer in postazioni diverse, assicurati che ogni browser sia impostato in modo da soddisfare le tue preferenze.
        <br>
        Puoi eliminare tutti i cookie installati nella cartella dei cookie del tuo browser. Ciascun browser presenta procedure diverse per la gestione delle impostazioni.
    </p>
    <h4>Esercizio dei diritti da parte degli Utenti</h4>
    <p>
        I soggetti cui si riferiscono i Dati Personali hanno il diritto in qualunque momento di ottenere la conferma dell'esistenza o meno degli stessi presso il Titolare del Trattamento, di conoscerne il contenuto e l'origine, di verificarne l'esattezza o chiederne l’integrazione, la cancellazione, l'aggiornamento, la rettifica, la trasformazione in forma anonima o il blocco dei Dati Personali trattati in violazione di legge, nonché di opporsi in ogni caso, per motivi legittimi, al loro trattamento. Le richieste vanno rivolte al Titolare del Trattamento.
        <br>
        L’utente ha altresì il diritto di fare un reclamo, per questioni inerenti al trattamento dei dati personali, all’Autorità competente (per l’Italia, il Garante per la Protezione dei Dati Personali – www.gpdp.it).
    </p>
    <h4>Modifiche a questa privacy policy</h4>
    <p>
        Ultimo aggiornamento: 5/08/2022
        <br>
        Il Titolare del Trattamento si riserva il diritto di apportare modifiche alla presente privacy policy in qualunque momento. Si prega dunque di consultare spesso questa pagina, prendendo come riferimento la data di ultima modifica indicata in fondo.
    </p>
</main>
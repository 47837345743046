<div class="main">
  <div class="pl1">
    <div class="pl1__a"></div>
    <div class="pl1__b"></div>
    <div class="pl1__c"></div>
    <div class="pl1__d"></div>
  </div>

  <p>{{ azione }}</p>
</div>

<footer>
  <div>
    <ol>
      <li>
        <img src="../../../assets/img/home/logo_benelli.jpeg" alt="logo benelli" class="logo-benelli">
      </li>
    </ol>
    <!-- <ol>
      <li class="logo-detail">
        <app-logo></app-logo>
        <app-socials></app-socials>
      </li>
    </ol> -->
    <ul>
      <p>
        Info & Supporto
      </p>
      <li>
        <a routerLink="/tos">Privacy e cookies</a>
      </li>
      <li>
        <a routerLink="/general-conditions">Sezione reclami</a>
      </li>
      <li>
        <a (click)="openFile('allegato_3.pdf')">Informazioni sul distributore</a>
      </li>
      <li>
        <a (click)="openFile('allegato_4ter.pdf')">Obblighi di comportamento</a>
      </li>
      <li>
        <a (click)="openFile('allegato_4.pdf')">Informazioni sulla distribuzione del prodotto</a>
      </li>
    </ul>
    <ul>
      <p>
        Contattaci
      </p>
      <li class="email">
        <!-- Icona Mail -->
        <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-mail"
        width="18"
        height="18"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#2c3e50"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path
          stroke="currentColor"
          d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z"
        />
        <path stroke="currentColor" d="M3 7l9 6l9 -6" />
      </svg>
      <a href="mailto:myvet@benelliassicurazioni.it">
        myvet&#64;benelliassicurazioni.it
      </a>
      </li>
    </ul>
    <ul>
      <p>
        Scarica l'App MyVet
      </p>
      <li>
        <button type="button" (click)="goToAppStore()">
          <!-- Icona Apple -->
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
            <!-- !Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
            <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/>
          </svg>
          App Store
        </button>
      </li>
      <li>
        <button type="button" (click)="goToPlayStore()">
          <!-- Icona Google Play Store -->
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
            <path d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z"/>
          </svg>
          Play Store
        </button>
      </li>
    </ul>
  </div>
  <div class="borderTop">
    <p>
      Iscrizione al R.U.I. n&deg; B000083181 <br>
      Responsabile dell'attività di intermediazione Luigi Benelli (iscrizione al R.U.I. n&deg; B000053556) <br>
      Benelli Assicurazioni è regolarmente soggetta al controllo dell'IVASS <br>
      Benelli Assicurazioni s.r.l. Via Rosselli del Turco, 30 47122 Forlì (FC) P.Iva 02641180407 Capitale Sociale  i.v. € 10.000
    </p>
  </div>

</footer>

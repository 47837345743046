<main>
  <div id="row-1">
    <img src="../../assets/svg/blue-line.svg" alt="" class="blue-line" />
    <div class="insure-start-container">
      <h1>Prenditi cura del tuo <span class="accent">Pet</span></h1>
      <label
        >Scopri il pacchetto su misura per il tuo amico a quattro zampe in meno di un minuto.</label
      >
      <div class="input-button-container">
        <div class="input">
          <app-input
            label="false"
            placeholder="Scrivi il nome del tuo Pet..."
            name="nome"
            autocomplete="given-name"
            [required]="false"
            [(ngModel)]="petName"
          >
          </app-input>
        </div>
        <div class="button">
          <button
            routerLink="/registration/1"
            [queryParams]="{ clear: 'clear', petName: petName }"
          >
            Preventivo gratuito
          </button>
        </div>
      </div>
    </div>

    <div class="orange-container"></div>
  </div>
  <div id="row-2">
    <div class="insure-benefits">
      <h3>I vantaggi di una protezione dedicata servizio "Care"</h3>
      <div class="benefits">
        <div class="benefit">
          <img src="../../assets/svg/shield.svg" alt="" />
          <h4>Pacchetti completi</h4>
          <label
            >Tutte le spese sanitarie dei vostri Pets, in caso di malattia o
            infortunio, vengono rimborsate.</label
          >
        </div>
        <div class="benefit">
          <img src="../../assets/svg/hand-heart.svg" alt="" />
          <h4>Nessun pensiero</h4>
          <label
            >Per le spese coperte dai pacchetti, scegli liberamente il veterinario.
          </label>
        </div>
        <div class="benefit">
          <img src="../../assets/svg/coins.svg" alt="" />
          <h4>Spese di prevenzione rimborsate</h4>
          <label
            >Visite comportamentali, visite preventive, vaccinazioni,
            castrazioni o sterilizzazioni.
          </label>
        </div>
      </div>
    </div>
  </div>
  <div id="row-3">
    <h3>Servizi dedicati su misura per cani e gatti</h3>
    <div class="pacchetti">
      <div class="pacchetto">
        <img src="../../assets/img/home/cane-home.png" alt="" />
        <h4>Pacchetti per <span class="accent">Cani</span></h4>
        <button [routerLink]="['/showcase']" [queryParams]="{ type: 'cani' }">
          Scopri di più
        </button>
      </div>

      <div class="pacchetto">
        <img src="../../assets/img/home/gatto-home.png" alt="" />
        <h4>Pacchetti per <span class="accent">Gatti</span></h4>
        <button [routerLink]="['/showcase']" [queryParams]="{ type: 'gatti' }">
          Scopri di più
        </button>
      </div>
    </div>
  </div>
  <div id="row-4">
    <h3>I pacchetti di protezione comprendono:</h3>
    <div class="carousel">
      <div class="carousel-item" *ngFor="let item of carouselContent">
        <img [src]="item.icon" alt="" />
        <h4>{{ item.title }}</h4>
      </div>
    </div>
  </div>
  <div id="row-5">
    <section class="faq">
      <app-faq [faqs]="questions"></app-faq>
      <button class="contattaci" routerLink="/chiamatemi">
        <h5>Hai altre domande? Contattaci</h5>
      </button>
    </section>
  </div>
  <div id="row-6">
    <div class="insure-start-container">
      <h3>Prenditi cura del tuo <span class="accent">Pet</span></h3>
      <label
        >Con MyVet puoi ottenere piani di protezione personalizzati per
        soddisfare tutte le esigenze del tuo migliore amico.</label
      >
      <button routerLink="/registration/1" [queryParams]="{ clear: 'clear' }">
        Preventivo gratuito
      </button>
    </div>
  </div>
</main>

import { Component, ViewChild } from '@angular/core';
import { RouterModule } from '@angular/router';
import { InputComponent } from '../components/input/input.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Location } from '@angular/common';
import { DialogComponent } from '../components/dialog/dialog.component';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-chiamatemi',
  standalone: true,
  imports: [
    InputComponent,
    FormsModule,
    CommonModule,
    DialogComponent,
    RouterModule,
  ],
  templateUrl: './chiamatemi.component.html',
  styleUrl: './chiamatemi.component.scss',
})
export class ChiamatemiComponent {
  @ViewChild('modalConfirm', { static: false }) modalConfirm!: DialogComponent;
  @ViewChild('modalError', { static: false }) modalError!: DialogComponent;

  constructor(private location: Location) {}

  callRequest = {
    gender: '',
    name: '',
    surname: '',
    tel: '',
    date: '',
    time: '',
  };

  callRequestErrors = {
    name: '',
    surname: '',
    tel: '',
    date: '',
    time: '',
  };

  dummy1: boolean = false; //callRequest opt1 (signor/signora)
  dummy2: boolean = false; //callRequest opt2 (chiama ora/chiama alla data inserita)

  validateName() {
    this.callRequestErrors.name = '';

    // Rimuoviamo gli spazi dall'inizio e dalla fine
    const trimmedname = this.callRequest.name.trim();

    // Verifica che la stringa non sia vuota dopo aver rimosso gli spazi
    if (!trimmedname) {
      this.callRequestErrors.name += 'Il campo non può essere vuoto \n';
      return false;
    } else {
      // Espressione regolare che controlla lettere (anche accentate), spazi, apostrofi e trattini
      if (!/^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/.test(trimmedname)) {
        this.callRequestErrors.name +=
          'Possono essere usate solo lettere, apostrofi e trattini';
        return false;
      }
    }
    return true;
  }

  validateSurname() {
    this.callRequestErrors.surname = '';

    // Rimuoviamo gli spazi dall'inizio e dalla fine
    const trimmedSurname = this.callRequest.surname.trim();

    // Verifica che la stringa non sia vuota dopo aver rimosso gli spazi
    if (!trimmedSurname) {
      this.callRequestErrors.surname += 'Il campo non può essere vuoto \n';
      return false;
    } else {
      // Espressione regolare che controlla lettere (anche accentate), spazi, apostrofi e trattini
      if (!/^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/.test(trimmedSurname)) {
        this.callRequestErrors.surname +=
          'Possono essere usate solo lettere, apostrofi e trattini';
        return false;
      }
    }
    return true;
  }

  validateDate() {
    this.callRequestErrors.date = '';

    if (!this.callRequest.date && !this.dummy2) {
      this.callRequestErrors.date += 'Il campo non può essere vuoto \n';
      return false;
    }

    return true;
  }

  validateTelephone() {
    this.callRequestErrors.tel = '';

    if (!this.callRequest.tel) {
      this.callRequestErrors.tel += 'Il campo non può essere vuoto \n';
      return false;
    }

    if (!/^\d{10,15}$/.test(this.callRequest.tel.replace(/[\s()+\-]/g, ''))) {
      this.callRequestErrors.tel += 'Numero di telefono non valido \n';
      return false;
    }

    return true;
  }

  validateTime() {
    this.callRequestErrors.time = '';

    if (!this.callRequest.time && !this.dummy2) {
      this.callRequestErrors.time += 'Il campo non può essere vuoto \n';
      return false;
    }
    return true;
  }

  async sendContactRequest(event: MouseEvent) {
    if (
      this.validateName() &&
      this.validateSurname() &&
      this.validateDate() &&
      this.validateTime() &&
      this.validateTelephone()
    ) {
      (event.target as HTMLButtonElement).disabled = true;

      //imposto la data e ora in base a se l'utente ha inserito la data o se vuole essere chiamato subito
      let dataOra = !this.dummy2
        ? `${this.callRequest.date}T${this.callRequest.time}:00.000Z`
        : new Date().toISOString();

      const params = JSON.stringify({
        titolo: this.callRequest.gender ? 'Signora' : 'Signor',
        nome: this.callRequest.name,
        cognome: this.callRequest.surname,
        telefono: this.callRequest.tel,
        dataOra: dataOra,
      });

      try {
        const response = await fetch(
          environment.apiUrl + environment.apiVersion + '/users/help',
          {
            method: 'POST',
            body: params,
          }
        );

        const data = await response.json();

        if (!response.ok) {
          this.modalError.showModal();
          throw new Error(`${data.description}`);
        }

        this.modalConfirm.showModal();

        setTimeout(() => {
          this.location.back();
        }, 1200);
      } catch (error) {
        (event.target as HTMLButtonElement).disabled = false;
      }
    }
  }
}

import { Component } from '@angular/core';
import { LogoComponent } from '../logo/logo.component';
import { SocialsComponent } from '../socials/socials.component';
import { RouterModule } from '@angular/router';


@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [LogoComponent, SocialsComponent, RouterModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {

  /**
   * Apre il link dell'app store
   */
  goToAppStore(){
    window.open('https://apps.apple.com/it/app/myvet/id6463804287', '_blank');
  }

  /**
   * Apre il link del google play store
   */
  goToPlayStore(){
    window.open('https://play.google.com/store/apps/details?id=it.myvet&pcampaignid=web_share', '_blank');
  }

  /**
   * Apre il documento indicato
   */
  openFile(fileName :string){
    window.open('../../../assets/documents/' + fileName, '_blank');
  }
}

<div [class]="errorText ? 'input error' : 'input '">
  <div class="header">
    <select
      [attr.name]="name"
      [attr.id]="name + '-select-field'"
      [required]="required"
      [disabled]="disabled"
      [(ngModel)]="value"
      (change)="selectChange()"
      [attr.aria-describedby]="name + '-description'"
      [attr.aria-invalid]="errorText ? 'true' : 'false'"
    >
      <option *ngFor="let option of options" [value]="option.value">
        {{ option.label }}
      </option>
    </select>
    <label [for]="name">{{ label }}<span *ngIf="required">*</span></label>
  </div>
  <div class="footer">
    <div class="text">
      <small [id]="name + '-description'" aria-live="polite" *ngIf="!errorText">
        {{ helperText }}
      </small>
      <small [id]="name + '-description'" aria-live="polite" *ngIf="errorText">
        {{ errorText }}
      </small>
    </div>
  </div>
</div>

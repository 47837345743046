import { RouterModule, Router } from '@angular/router';
import { Component } from '@angular/core';
import { InputComponent } from '../components/input/input.component';
import { SelectComponent } from '../components/select/select.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ToggleComponent } from '../components/toggle/toggle.component';
import { LoadingComponent } from '../components/loading/loading.component';
import { SegmentComponent } from '../components/segment/segment.component';

@Component({
  selector: 'app-profile',
  standalone: true,
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  imports: [
    InputComponent,
    RouterModule,
    LoadingComponent,
    SelectComponent,
    FormsModule,
    CommonModule,
    ToggleComponent,
    SegmentComponent,
  ],
})
export class ProfileComponent {
  loading: boolean = true;

  sections = [
    { name: 'sezione1', label: 'Sezione 1', value: 1 },
    { name: 'sezione2', label: 'Sezione 2', value: 2 },
    { name: 'sezione3', label: 'Sezione 3', value: 3 },
  ];
  section = 1;

  constructor(private router: Router) {}

  async ngOnInit() {
    this.loading = false;
  }

  changeSection() {}
}

<dialog     
    #dialog 
    class="open-close-container"
    [class.error]="error" 
    [class.success]="success" 
>
    <h4>{{title}}</h4>
    <p [class.text]="text != ''">{{text}}</p>
    <div class="actions" *ngIf="confirmButton || cancelButton">
        <button *ngIf="cancelButton" (click)="closeModal()" class="cancel">Annulla</button>
        <button *ngIf="confirmButton" (click)="confirmMod()" class="confirm">Conferma</button>
    </div>
</dialog>
<button #show (click)="showModal()" hidden></button>
import { CommonModule } from '@angular/common';
import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormsModule } from '@angular/forms'; 

@Component({
  selector: 'app-input',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})

// ControlValueAccessor serve per rendere il nostro componente compatibile con le funzioni di Angular e ci permette di usare [(ngModel)] ovvero il Two Way Binding
export class InputComponent implements ControlValueAccessor {
  // Input fields
  @Input() label: string = 'Test Label';
  @Input() name: string = '';
  @Input() type:string = 'text';
  @Input() placeholder = '';
  @Input() autocomplete?: string;

  // Input behaviours
  @Input() required: boolean = false;
  @Input() autofocus = false;
  @Input() readonly = false;
  @Input() min?: any;
  @Input() max?: any;
  @Input() minlength?: number;
  @Input() maxlength?: string;

  //Error Handling
  @Input() helperText: string = '';
  @Input() errorText: string = '';

  innerValue: any = '';
  onChange: any = () => {}; // contiene viene invocata al cambio dell'input
  onTouched: any = () => {}; // Funzione che viene invocata al tocco dell'input

  showPassword: boolean = false;

  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChange(v);
    }
  }

  /**
   * Aggiorna l'ngModel del componente quando viene cambiato dall'esterno del componente
   * @param value
   */
  writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
    }
  }

  /**
   * Funzione chiamata quando il componente perde il focus
   */
  onBlur(): void {
    this.onTouched();
  }

  /**
   * Funzione nativa di Angular per associare l'evento del cambio dell'imput ad una funzione di propria scelta
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Funzione nativa di Angular per associare l'evento al tocco ad una funzione di propria scelta
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  get characterCount(): number {
    if(this.value) return this.value.length;
    return 0;
  }

  togglePasswordVisibility() {
    if (this.type == 'password') {
      this.showPassword = !this.showPassword;
    }
  }
}

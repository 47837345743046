import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { InsureComponent } from './insure/insure.component';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { RegistrationComponent } from './registration/registration.component';
import { ShowcaseComponent } from './showcase/showcase.component';
import { ChiamatemiComponent } from './chiamatemi/chiamatemi.component';
import { TosComponent } from './legal-docs/tos/tos.component';
import { GeneralConditionsComponent } from './legal-docs/general-conditions/general-conditions.component';

export const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'showcase', component: ShowcaseComponent },
  { path: 'chiamatemi', component: ChiamatemiComponent },
  { path: 'tos', component: TosComponent },
  { path: 'general-conditions', component: GeneralConditionsComponent },

  { path: 'registration/:step', component: RegistrationComponent },
  { path: 'registration', redirectTo: '/registration/1', pathMatch: 'full' },

  { path: 'insure/:step', component: InsureComponent },
  { path: 'insure', redirectTo: '/insure/4', pathMatch: 'full' },

  { path: '', redirectTo: '/home', pathMatch: 'full' },
];

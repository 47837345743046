<div [class]="'container' + (error ? ' error' : '')">
  <label class="switch" [for]="name + '-checkbox-field'">
    <input
      type="checkbox"
      [attr.id]="name + '-checkbox-field'"
      [attr.name]="name"
      [attr.aria-describedby]="name + '-description'"
      [attr.required]="required"
      [checked]="checked"
      (change)="toggleChecked()"
    />
    <div class="slider round"></div>
  </label>

  <p aria-live="polite">
    <span *ngIf="required">*</span>
  </p>
  <p [innerHTML]="label" [id]="name + '-description'" (click)="toggleExpansion()" [class.expandable]="expanded">
  </p>
</div>

<span>
  <a href="https://www.instagram.com/myvet_italy/" target="_blank">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="icon"
      width="44"
      height="44"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="#2c3e50"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path
        stroke="currentColor"
        d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z"
      />
      <path
        stroke="currentColor"
        d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"
      />
      <path stroke="currentColor" d="M16.5 7.5l0 .01" />
    </svg>
  </a>
  <a href="https://it.linkedin.com/company/myvet-italy" target="_blank">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="icon"
      width="44"
      height="44"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="#2c3e50"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path
        stroke="currentColor"
        d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"
      />
      <path stroke="currentColor" d="M8 11l0 5" />
      <path stroke="currentColor" d="M8 8l0 .01" />
      <path stroke="currentColor" d="M12 16l0 -5" />
      <path stroke="currentColor" d="M16 16v-3a2 2 0 0 0 -4 0" />
    </svg>
  </a>
</span>

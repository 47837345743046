<!-- Top bar -->
<div class="top-bar" *ngIf="(step == 4 || step == 10) && !isModal">
  <app-logo></app-logo>
  <button routerLink="/chiamatemi">Chiamatemi</button>
</div>
<div class="top-form-bar" *ngIf="step !== 4 && step !== 10 && step !== 9">
  <div
    class="step"
    [ngClass]="{ disabled: step != 5 }"
    id="step5"
  >
    <div class="step-circle">1</div>
    <div class="step-text gradient">Dati del tuo animale</div>
  </div>
  <!-- <div
    class="step"
    [ngClass]="{ disabled: step != 6 }"
    id="step7"
  >
    <div class="step-circle">2</div>
    <div class="step-text gradient">Servizi aggiuntivi</div>
  </div> -->
  <div
    class="step"
    [ngClass]="{ disabled: step != 6 }"
    id="step7"
  >
    <div class="step-circle">2</div>
    <div class="step-text gradient">Info cliente</div>
  </div>
  <div
    class="step"
    [ngClass]="{ disabled: step != 7 }"
    id="step7"
  >
    <div class="step-circle">3</div>
    <div class="step-text gradient">Info animale</div>
  </div>
  <div
    class="step"
    [ngClass]="{ disabled: step != 8 }"
    id="step8"
  >
    <div class="step-circle">4</div>
    <div class="step-text gradient">Riepilogo</div>
  </div>
  <div class="final-price">
    €
    <span style="font-size: var(--font-l)" class="gradient"
      >{{ pet.insurance.prezzo.split(".")[0]
      }}<span style="font-size: var(--font-m)"
        >,{{
          pet.insurance.prezzo.split(".")[1]
        }}
        </span
      >
    </span>
  </div>
</div>
<div class="divider" *ngIf="step !== 4 && step !== 9 && step !== 10"></div>
<main>
  <app-dialog
    #show
    title="Sei sicuro di voler uscire dalla compilazione?"
    text="Se accetti perderai tutti i dati inseriti fino a questo punto."
    [confirmButton]="true"
    [cancelButton]="true"
    (emitResponse)="modalResponse($event)"
  >
  </app-dialog>
  <div>
    <div class="insurance-form">

      <!--#start region packs-->
      <div *ngIf="step == 4" id="step-4">
        <div class="top-logo">
          <img [attr.src]="'../assets/svg/check.svg'" alt="check Image" />
        </div>
        <h2>
          Il pacchetto <span class="accent">{{ pet.insurance.nomeAssicurazione }}</span> è perfetto per te!
        </h2>
        <div class="middle">
          <div class="pet">
            <div class="content">
              <div class="image-name">
                <div class="white-circle">
                  <img
                    [attr.src]="'../assets/svg/' + pet.specie + '.svg'"
                    alt="Pet Image"
                  />
                </div>
                <label
                  ><span class="gradient">{{
                    pet.name.charAt(0).toUpperCase() + pet.name.slice(1)
                  }}</span
                  ><br />{{
                    pet.race.value !== "" ? pet.race.label : "meticcio"
                  }}, {{ pet.age }}
                  {{ pet.age == "0-11" ? "mesi" : "anni" }}</label
                >
              </div>
            </div>
          </div>
          <!--start scelta ass-->
          <div class="insurances-summary">
            <ng-container *ngFor="let insurance of insurances">
              <div
                class="insurance-option"
                [class.selected]="
                  insurance.idAssicurazione === pet.insurance.idAssicurazione
                "
                (click)="selectInsurance(insurance)"
                *ngIf="insurance.nomeSpecie === pet.specie"
              >
                <div class="price">
                  <h3>
                    <span style="font-size: var(--font-l)">{{
                      (insurance.prezzo / 12).toFixed(2).split(".")[0] +
                        "," +
                        (insurance.prezzo / 12).toFixed(2).split(".")[1]
                    }}</span>
                    €
                  </h3>
                  <label> al mese</label>
                </div>
              </div>
            </ng-container>
          </div>

          <div class="insurances">
            <ng-container *ngFor="let insurance of insurances">
              <div
                class="insurance-option insurance-option-mobile"
                [class.selected]="
                  insurance.idAssicurazione === pet.insurance.idAssicurazione
                "
                (click)="selectInsurance(insurance)"
                *ngIf="insurance.nomeSpecie === pet.specie"
              >
                <div>
                  <div class="title">
                    <h3>{{ insurance.nomeAssicurazione }}</h3>
                    <div class="list">
                      <ul>
                        <ng-container
                          *ngFor="
                            let infoItem of insurance.descrizione;
                            let i = index;
                            let lastItem = last;
                          "
                        >
                          <li
                              *ngIf="!infoItem[0].includes('\u2705') && !lastItem"
                              class="formattato"
                            >
                            <label class="subtitle" [class.subtitleMobile]="isMobile">
                              {{ infoItem }}
                            </label>
                          </li>
                          <li
                            *ngIf="infoItem[0].includes('\u2705')"
                            class="formattato"
                          >
                            <span
                              *ngIf="
                                (
                                  this.pet.prezzoAumentato ||
                                  (
                                    !this.pet.prezzoAumentato &&
                                    (i <= 3)
                                  )
                                ) && infoItem[0].includes('\u2705')
                              "
                            >
                              {{ infoItem[0] }}
                            </span>
                            <span
                              *ngIf="
                                !this.pet.prezzoAumentato &&
                                  (i > 3);
                                infoItem: [0].includes('\u2705')
                              "
                            >
                              ❌
                            </span>
                            <span>
                              {{ infoItem[1] }}
                            </span>
                          </li>
                        </ng-container>
                      </ul>
                    </div>
                  </div>

                  <label class="description">{{
                    insurance.descrizione.slice(-1)
                  }}</label>
                </div>
                <button
                  [class.opacity]="insurance.idAssicurazione !== pet.insurance.idAssicurazione"
                  (click)="insurance.idAssicurazione !== pet.insurance.idAssicurazione ? null : setModal(insurance.clausola)"
                >
                  Che cosa copre?
                </button>

                <div class="card-bottom">
                  <div class="price">
                    <h3>
                      €
                      <span style="font-size: var(--font-l)">{{
                        (insurance.prezzo / 12).toFixed(2).split(".")[0] +
                          "," +
                          (insurance.prezzo / 12).toFixed(2).split(".")[1]
                      }}</span>
                    </h3>
                    <label> / mese</label>
                  </div>
                  <label>€ {{insurance.prezzo.split(".")[0] + ',' + insurance.prezzo.split(".")[1]}} tot.</label>
                  <img
                    [attr.src]="
                      insurance.id == pet.insurance.id
                        ? '../assets/svg/check.svg'
                        : '../assets/svg/check-disabled.svg'
                    "
                    alt="check Image"
                  />
                </div>
              </div>
            </ng-container>
          </div>

          <div id="myModal" class="modal" *ngIf="isModal">
            <div class="modal-content">
              <span class="close" id="closeModalBtn" (click)="setModal([])"
                >&times;</span
              >
              <h3 class="accent">{{ pet.insurance.nomeAssicurazione }}</h3>

              <p>{{ modalText[0].title }}</p>

              <ng-container *ngFor="let paragraph of modalText.slice(1, -1)">
                <p>{{ paragraph.titolo }}</p>

                <details>
                  <summary>
                    {{ paragraph?.title }}
                  </summary>
                  <ul *ngFor="let item of paragraph?.items">
                    <li *ngIf="item.includes('<subtitle>')" class="modal-subtitle">
                      {{ item.replace("\<subtitle>", '') }}
                    </li>
                    <li *ngIf="!item.includes('<subtitle>')">
                      {{ item }}
                    </li>
                  </ul>
                </details>
              </ng-container>
            </div>
          </div>

          <div class="toggle-container" *ngIf="!isMobile">
            <app-toggle
              class="toggle_prz-ridotto"
              [(ngModel)]="pet.prezzoAumentato"
              name="prezzo_ridotto"
              [required]="false"
              label="Aggiungi le coperture assicurative"
              (change)="aumentareTotale()"
            ></app-toggle>
          </div>

          <div class="insure-first-container">
            <h4 style="color: var(--primary-color); text-align: left">
              Come scegliere?
            </h4>
            <p>
              Il massimale annuo di rimborso corrisponde all' importo massimo
              rimborsabile in caso di necessità.
            </p>
            <p>
              Si consiglia di scegliere il massimale di rimborso in base ai
              rischi legati all'età del proprio cane:
            </p>

            <ul>
              <li>
                Da 3 mesi a 3 anni: il tuo cucciolo è curioso e sperimenta di
                tutto; i rischi d'infortunio sono quindi particolarmente
                elevati. <br /><strong>> Pacchetto consigliato: <span class="accent">Gold</span></strong>
              </li>
              <li>
                Da 3 mesi a 5 anni: il tuo amico a quattro zampe è nella fase
                migliore della sua vita

                <br /><strong>> Pacchetto consigliato: <span class="accent">Silver</span></strong>
              </li>
              <li>
                Oltre 5 anni : il tuo cane inizia a invecchiare e la sua salute
                diventa sempre più fragile.<br /><strong
                  >> Pacchetto consigliato: <span class="accent">Gold</span></strong
                >
              </li>
            </ul>
          </div>
          <!--end scelta ass-->
          <h4 class="margin-y">Incluso nel pacchetto</h4>

          <div class="packs">
            <label
              class="insurance-option"
              *ngFor="let service of insuranceServices"
            >
              <div class="title">
                <label class="gradient">{{ service.nome }}</label>
              </div>
              <label class="description">
                {{ service.descrizione }}
              </label>
              <div class="price">
                <label class="gradient">{{ service.prezzo }} €</label>
              </div>
              <a (click)="setModalServices(service.clausola)">maggiori info</a>
            </label>
          </div>
        </div>

        <div class="conditions">
          <small>Il seguente documento si riferisce esclusivamente alla polizza di assicurazione Unipol</small>
          <button (click)="openPdfGeneralConditions()">
            <img [attr.src]="'../assets/svg/download.svg'" />Set Informativo di Polizza
          </button>
        </div>

        <div id="myModal" class="modal smaller" *ngIf="isModal2">
          <div class="modal-content smaller">
            <span
              class="close"
              id="closeModalBtn"
              (click)="setModalServices([])"
              >&times;</span
            >

            <p class="formatatto" *ngFor="let item of modal2Text">
              {{ item }}
            </p>
          </div>
        </div>

      </div>
      <!--#end region packs-->
      <!--#start region dati assicurazione-->
      <div *ngIf="step == 5" id="step-5">
        <div class="rectangle-container" [class.enlarge]="setMicrochip == 1">
          <div class="rectangle-top">
            <div class="pet">
                <div class="image-name">
                    <img
                      [attr.src]="'../assets/svg/' + pet.specie + '.svg'"
                      alt="Pet Image"
                    />

                  <label
                    ><span class="gradient">{{
                      pet.name.charAt(0).toUpperCase() + pet.name.slice(1)
                    }}</span
                    ><br />{{
                      pet.race.value !== "" ? pet.race.label : "meticcio"
                    }}, {{ pet.age }}
                    {{ pet.age == "0-11" ? "mesi" : "anni" }}</label
                  >
                </div>
            </div>
            <!-- Right content with a rounded select -->
            <div class="right-select">
              <button
                [ngClass]="setMicrochip == 0 ? 'rounded-select' : 'select-opt'"
                [class.errorButton]="petErrors.setIdentificativo"
                (click)="setSelect(setMicrochip)"
                style="margin: 0 !important"
              >
                <span *ngIf="setMicrochip == 0">Ha il microchip?</span>
                <span *ngIf="setMicrochip == 1">Sì, ce l'ha</span>
                <span *ngIf="setMicrochip == 2">No, ha il tatuaggio</span>
                <img
                  *ngIf="setMicrochip == 0"
                  [attr.src]="'../assets/svg/arrow-down.svg'"
                />
              </button>
              <button
                class="select-opt"
                [style.display]="optionsOpen ? 'block' : 'none'"
                (click)="setSelect(1)"
              >
                Sì, ce l'ha
              </button>
              <button
                class="select-opt"
                [style.display]="optionsOpen ? 'block' : 'none'"
                (click)="setSelect(2)"
              >
                No, ha il tatuaggio
              </button>
            </div>
          </div>

          <ng-container *ngIf="setMicrochip == 1">
            <app-input
              label="Numero microchip"
              name="microchip"
              maxlength="15"
              [type]="'text'"
              [placeholder]="'Inserisci il codice di 15 cifre'"
              [errorText]="petErrors.microchip"
              (input)="validateMicrochip()"
              [(ngModel)]="pet.microchip"
            ></app-input>

            <app-input
              type="date"
              [max]="today"
              label="Data di microchip"
              name="data"
              [errorText]="petErrors.dataMicrochip"
              (input)="validateMicrochipDate()"
              [(ngModel)]="pet.dataMicrochip"
            ></app-input>
          </ng-container>

          <ng-container *ngIf="setMicrochip == 2">
            <app-input
              label="Numero tatuaggio"
              name="tatuaggio"
              [placeholder]="'Inserisci il codice di 12 caratteri alfanumerici'"
              [errorText]="petErrors.tatuaggio"
              (input)="validateTatuaggio()"
              [(ngModel)]="pet.tatuaggio"
            ></app-input>

            <app-input
              type="date"
              [max]="today"
              label="Data di tatuaggio"
              name="data"
              [errorText]="petErrors.dataTatuaggio"
              (input)="validateTatuaggioDate()"
              [(ngModel)]="pet.dataTatuaggio"
            ></app-input>
          </ng-container>

          <app-input
            *ngIf="setMicrochip > 0"
            type="date"
            [max]="today"
            label="Data di nascita"
            name="data"
            [errorText]="petErrors.dataNascita"
            (input)="validateDate()"
            (onChange)="validateDate()"
            [(ngModel)]="pet.dataNascita"
          ></app-input>
        </div>
        <div class="buttons-section">
          <button (click)="nextStep()">Continua</button>
        </div>
      </div>

      <!-- <div *ngIf="step == 6" id="step-6">
        <div>
          <div class="rectangle-container" [class.enlarge]="pet.card">

            <div class="radios">
              <input
                type="radio"
                name="tipo"
                [checked]="pet.card"
                (click)="setRadio()"
              />
              <div class="radio-option-label">
                <div class="card-top" (click)="setRadio()">
                  <div class="description">
                    <label class="gradient">
                      Carta d'identità di
                      {{ pet.name.charAt(0).toUpperCase() + pet.name.slice(1) }}
                    </label>
                    <p>
                      Ricevi a casa un documento con tutte le informazioni utili
                      sul tuo Pet come il n. microchip, la razza, la taglia e il
                      n. passaporto
                    </p>
                  </div>
                  <div class="card-price" class="gradient">
                    €
                    <span style="font-size: var(--font-l)" class="gradient">{{
                      cardPrezzo
                    }}</span>
                  </div>
                </div>
                <div *ngIf="pet.card">
                  <p class="title" class="gradient">Seleziona il colore</p>
                  <div class="images">
                    <ng-container *ngFor="let cardColor of cardColors">
                      <img
                        [src]="cardColor.img"
                        [alt]="cardColor.titolo"
                        [class.selected]="pet.cardColor === cardColor.titolo"
                        (click)="setCard(cardColor.titolo)"
                      />
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer-info">
            <div>
              <p *ngIf="pet.card">
                Riceverai un'email con un modulo da compilare con tutti i dati
                necessari alla realizzazione della tua carta d'identità
              </p>
            </div>
            <button (click)="nextStep()">Continua</button>
          </div>
        </div>
      </div> -->

      <div *ngIf="step == 6" id="step-7">
        <h2>Informazione cliente</h2>

        <app-input
          placeholder="Mario"
          label="Nome"
          name="nome"
          autocomplete="given-name"
          [required]="true"
          [errorText]="accountErrors.name"
          (input)="validateName()"
          [(ngModel)]="account.name"
        ></app-input>

        <app-input
          placeholder="Rossi"
          label="Cognome"
          name="cognome"
          autocomplete="family-name"
          [required]="true"
          [errorText]="accountErrors.surname"
          (input)="validateSurname()"
          [(ngModel)]="account.surname"
        ></app-input>

        <app-input
          placeholder="322 348 4477"
          label="Telefono"
          name="telefono"
          [required]="true"
          [errorText]="accountErrors.telephone"
          (input)="validateTelephone()"
          [(ngModel)]="account.telephone"
        ></app-input>

        <div class="split">
          <app-input
            type="email"
            placeholder="nome@gmail.com"
            label="E-Mail"
            name="email"
            autocomplete="email"
            [required]="true"
            [errorText]="accountErrors.email"
            (input)="validateEmail()"
            [(ngModel)]="account.email"
          ></app-input>

          <app-input
            type="email"
            placeholder="nome@gmail.com"
            label="Conferma E-Mail"
            name="email"
            [required]="true"
            [errorText]="accountErrors.confermaEmail"
            (input)="validateConfermaEmail()"
            [(ngModel)]="account.confermaEmail"
          ></app-input>
        </div>

        <app-input
          placeholder="NNNCCC00M11P222S"
          label="Codice Fiscale"
          name="codice-fiscale"
          [required]="true"
          [errorText]="accountErrors.cf"
          (input)="validateCF()"
          [(ngModel)]="account.cf"
        ></app-input>

        <app-input
          type="text"
          placeholder="Forli"
          label="Luogo di nascita"
          name="city"
          autocomplete="city"
          [required]="true"
          [errorText]="accountErrors.luogoNascita"
          (input)="validateLuogoNascita()"
          [(ngModel)]="account.luogoNascita"
        ></app-input>

        <app-input
          type="text"
          placeholder="Italia"
          label="Stato di nascita"
          name="stato"
          autocomplete="stato"
          [required]="true"
          [errorText]="accountErrors.statoNascita"
          (input)="validateStato()"
          [(ngModel)]="account.statoNascita"
        ></app-input>

        <app-input
          type="date"
          [max]="minAccountBirthDate"
          label="Data di nascita"
          name="data"
          [errorText]="accountErrors.dataNascita"
          (input)="validateDataNascita()"
          [(ngModel)]="account.dataNascita"
        ></app-input>

        <div class="split">
          <app-input
            type="text"
            placeholder="Inserisci indirizzo..."
            label="Indirizzo di residenza"
            name="address"
            autocomplete="address"
            [required]="true"
            [errorText]="accountErrors.address"
            (input)="validateAddress()"
            [(ngModel)]="account.address"
          ></app-input>

          <app-input
            type="text"
            placeholder="Inserisci città..."
            label="Città di residenza"
            name="city"
            autocomplete="city"
            [required]="true"
            [errorText]="accountErrors.city"
            (input)="validateCity()"
            [(ngModel)]="account.city"
          ></app-input>
        </div>

        <app-input
          type="text"
          placeholder="47121"
          label="CAP"
          name="cap"
          autocomplete="cap"
          [required]="true"
          [errorText]="accountErrors.cap"
          (input)="validateCap()"
          [(ngModel)]="account.cap"
        ></app-input>

        <app-select
          label="Tipo di documento "
          name="documento"
          [required]="true"
          [errorText]="accountErrors.documento"
          [options]="documenti"
          (change)="validateDocumento()"
          [(ngModel)]="account.documento"
        >
        </app-select>

        <app-input
          label="Numero di documento"
          name="numero documento"
          [required]="true"
          [placeholder]="'Inserisci il numero del documento'"
          [errorText]="accountErrors.numeroDoc"
          (input)="validateNumeroDoc()"
          [(ngModel)]="account.numeroDoc"
        ></app-input>

        <app-input
          type="date"
          label="Data di scadenza"
          name="data"
          [min]="today"
          [required]="true"
          [errorText]="accountErrors.dataScadenza"
          (input)="validateDataScadenza()"
          [(ngModel)]="account.dataScadenza"
        ></app-input>

        <div class="buttons-section">
          <button (click)="nextStep()">Continua</button>
        </div>
      </div>

      <div *ngIf="step == 7" id="step-8">
        <h2>Conferma dati Pet</h2>

        <label [class.error]="petErrors.specie" color="primary">Che animale è?<span>*</span></label><br>
        <div class="radios">
          <label *ngFor="let specie of species"
            class="radios-option" [for]="specie.nome"
            (click)="selectSpecies(specie)"
            [class.error]="petErrors.specie"
            [class.selected]="pet.specie === specie.nome"
          >
            <div class="radio-option-label">
              <img [attr.src]="petErrors.specie ? '../assets/svg/'+specie.nome+'-error.svg' : '../assets/svg/'+specie.nome+'.svg'" [alt]="'Immagine '+specie.nome">
              <label [for]="specie.nome">{{specie.nome}}</label>
            </div>
            <input type="radio" [value]="specie.nome" name="tipo" [id]="specie.nome" [checked]="pet.specie === specie.nome">
          </label>
        </div>

        <app-input
          label="Come si chiama?"
          name="nome"
          autocomplete="given-name"
          [required]="true"
          [errorText]="petErrors.name"
          (input)="validatePetName()"
          [(ngModel)]="pet.name"
        >
        </app-input>

        <label [class.error]="petErrors.gender">Di che sesso è?<span>*</span></label><br>
        <div class="radios">
          <label *ngFor="let gender of genders"
            class="radios-option" [for]="gender.nome"
            (click)="selectGender(gender)"
            [class.error]="petErrors.gender"
            [class.selected]="pet.gender === gender.value"
          >
            <div class="radio-option-label">
              <label [for]="gender.nome">{{gender.nome}}</label>
            </div>
            <input type="radio" [value]="gender.value" name="sesso" [id]="gender.nome" [checked]="pet.gender === gender.value">
          </label>
        </div>

        <app-select
          label="Quanti anni ha?"
          name="anni"
          [required]="true"
          [errorText]="petErrors.age"
          [options]="petAges"
          (change)="validateAge()"
          [(ngModel)]="pet.age">
        </app-select>

        <label [class.error]="petErrors.macroRace">Di che razza è?<span>*</span></label><br>
        <div class="radios">
          <label
            class="radios-option" for="meticcio"
            (click)="selectMacroRace('meticcio')"
            [class.selected]="pet.macroRace === 'meticcio'"
            [class.error]="petErrors.macroRace"
          >
            <div class="radio-option-label">
              <label for="meticcio">Meticcio</label>
            </div>
            <input [disabled]="pet.specie==''" type="radio" value="meticcio" name="razza" id="meticcio" [checked]="pet.macroRace === 'meticcio'">
          </label>

          <label
            class="radios-option" for="di razza"
            (click)="selectMacroRace('di razza')"
            [class.selected]="pet.macroRace === 'di razza'"
            [class.error]="petErrors.macroRace"
          >
            <div class="radio-option-label">
              <label for="di razza">Di razza</label>
            </div>
            <input [disabled]="pet.specie==''" type="radio" value="di razza" name="razza" id="di razza" [checked]="pet.macroRace === 'di razza'">
          </label>
        </div>

        <ng-container *ngIf="pet.macroRace==='di razza' && pet.specie">
          <app-select
            label="Seleziona razza"
            name="razza"
            [required]="true"
            [errorText]="petErrors.race"
            [options]="races"
            (change)="selectRace()"
            [(ngModel)]="pet.race.value"
            >
          </app-select>
        </ng-container>

        <div *ngIf="pet.macroRace==='meticcio' && pet.specie ==='Cane'">
          <label [class.error]="petErrors.size">Di che taglia è?<span>*</span></label><br>
          <div class="sizes">
            <label
              *ngFor="let size of sizes"
              class="size-option"
              (click)="selectSize(size)"
              [class.selected]="pet.size == size.idTaglia"
              [class.error]="petErrors.size"
            >
              <h4>{{size.nome}}</h4>
              <label>{{size.pesoMinimo}} - {{size.pesoMassimo}} kg</label>
            </label>
          </div>
        </div>

        <ng-container *ngIf="setMicrochip == 1">
          <app-input
            label="Numero microchip"
            name="microchip"
            maxlength="15"
            [type]="'text'"
            [placeholder]="'Inserisci il codice di 15 cifre'"
            [errorText]="petErrors.microchip"
            (input)="validateMicrochip()"
            [(ngModel)]="pet.microchip"
          ></app-input>

          <app-input
            type="date"
            [max]="today"
            label="Data di microchip"
            name="data"
            [errorText]="petErrors.dataMicrochip"
            (input)="validateMicrochipDate()"
            [(ngModel)]="pet.dataMicrochip"
          ></app-input>
        </ng-container>

        <ng-container *ngIf="setMicrochip == 2">
          <app-input
            label="Numero tatuaggio"
            name="tatuaggio"
            [placeholder]="'Inserisci il codice di 12 caratteri alfanumerici'"
            [errorText]="petErrors.tatuaggio"
            (input)="validateTatuaggio()"
            [(ngModel)]="pet.tatuaggio"
          ></app-input>

          <app-input
            type="date"
            [max]="today"
            label="Data di tatuaggio"
            name="data"
            [errorText]="petErrors.dataTatuaggio"
            (input)="validateTatuaggioDate()"
            [(ngModel)]="pet.dataTatuaggio"
          ></app-input>
        </ng-container>

        <app-input
          *ngIf="setMicrochip > 0"
          type="date"
          [max]="today"
          label="Data di nascita"
          name="data"
          [errorText]="petErrors.dataNascita"
          (input)="validateDate()"
          [(ngModel)]="pet.dataNascita"
        ></app-input>
        <div class="buttons-section">
          <button (click)="nextStep()">Continua</button>
        </div>
      </div>

      <div *ngIf="step == 8" id="step-9">
        <!--riepilogo-->
        <h2 class="gradient">Riepilogo</h2>
        <div class="info-container">
            <!-- Card 1 -->
            <details class="cell">
              <summary>
                <h4>Proprietario</h4>
              </summary>

              <div class="answer">
                <div class="text">
                  <ul>
                    <li>
                      <span>Nome:</span><br />&nbsp;&nbsp;{{
                        account.name.charAt(0).toUpperCase() +
                          account.name.slice(1)
                      }}
                    </li>
                    <li>
                      <span>Cognome:</span><br />&nbsp;&nbsp;{{
                        account.surname.charAt(0).toUpperCase() +
                          account.surname.slice(1)
                      }}
                    </li>
                    <li>
                      <span>Email:</span><br />&nbsp;&nbsp;{{ account.email }}
                    </li>
                    <li>
                      <span>Tel:</span><br />&nbsp;&nbsp;{{ account.telephone }}
                    </li>
                    <li>
                      <span>Newsletter:</span><br />&nbsp;&nbsp;{{
                        account.newsletter ? "sì" : "no"
                      }}
                    </li>
                  </ul>
                </div>
                <button
                  class="empty"
                  (click)="setStep(6)"
                >
                  Modifica
                </button>
              </div>
            </details>
            <!-- Card 2 -->
            <details class="cell">
              <summary>
                <h4>MyVet Protection</h4>
              </summary>
              <div class="answer">
                <div class="text">
                  <p>{{ pet.insurance.nomeAssicurazione }}</p>
                  <p>
                    €
                    {{
                      (+pet.insurance.prezzo)
                        .toFixed(2)
                        .toString()
                        .split(".")[0] +
                        "," +
                        (+pet.insurance.prezzo)
                          .toFixed(2)
                          .toString()
                          .split(".")[1]
                    }}
                  </p>
                </div>
                <button class="empty" (click)="setStep(4)">Modifica</button>
              </div>
            </details>
            <!-- Card 1 -->
            <details class="cell">
              <summary>
                <h4>Animale</h4>
              </summary>
              <div class="answer">
                <div class="text">
                  <ul>
                    <li>
                      <span>Nome:</span><br />&nbsp;&nbsp;{{
                        pet.name.charAt(0).toUpperCase() + pet.name.slice(1)
                      }}
                    </li>
                    <li>
                      <span>Specie:</span><br />&nbsp;&nbsp;{{ pet.specie }}
                    </li>
                    <li>
                      <span>Razza:</span><br />&nbsp;&nbsp;{{
                        pet.race.value !== "" ? pet.race.label : "meticcio"
                      }}
                    </li>
                    <li>
                      <span>Anni:</span><br />&nbsp;&nbsp;{{ pet.age }}
                      {{ pet.age == "0-11" ? "mesi" : "" }}
                    </li>
                    <li *ngIf="pet.size">
                      <span>Taglia:</span><br />&nbsp;&nbsp;
                      <ng-container [ngSwitch]="pet.size">
                        <ng-container *ngSwitchCase="1">piccola</ng-container>
                        <ng-container *ngSwitchCase="2">media</ng-container>
                        <ng-container *ngSwitchDefault>grande</ng-container>
                      </ng-container>
                    </li>
                    <li *ngIf="pet.tatuaggio; else showMicrochip">
                      <span>Tatuaggio:</span><br />&nbsp;&nbsp;{{
                        pet.tatuaggio.toUpperCase()
                      }}
                    </li>
                    <ng-template #showMicrochip>
                      <li>
                        <span>Microchip:</span><br />&nbsp;&nbsp;{{
                          pet.microchip
                        }}
                      </li>
                    </ng-template>
                  </ul>
                </div>
                <button
                  class="empty"
                  (click)="setStep(7)"
                >
                  Modifica
                </button>
              </div>
            </details>
        </div>

        <div class="totale">
          <div>
            <h3>Totale:</h3>
            <span class="gradient"
              >€
              {{
                (+pet.insurance.prezzo)
                  .toFixed(2)
                  .toString()
                  .split(".")[0] +
                  "," +
                  (+pet.insurance.prezzo)
                    .toFixed(2)
                    .toString()
                    .split(".")[1]
              }}
              </span
            >
          </div>
        </div>

        <h2 class="gradient">Hai un codice veterinario?</h2>
        <div class="veterinario-code">
          <app-input
            label=""
            name="verinario"
            [placeholder]="'Inserisci il codice myvet o nome e cognome'"
            [helperText]="'Cos è un codice veterinario?'"
            [(ngModel)]="pet.veterinarioCode"
          >
          </app-input>

          <app-toggle
            class="tos"
            [(ngModel)]="account.tos"
            name="tos"
            [required]="true"
            [error]="accountErrors.tos"
            label="Ho preso visione della <a href='/tos' target='_blank'>Informativa Privacy</a> e presto il consenso a tutti i trattamenti obbligatori."
          ></app-toggle>

          <app-toggle
            *ngIf="this.pet.prezzoAumentato == 1"
            [(ngModel)]="account.general"
            name="general"
            [required]="true"
            [error]="accountErrors.general"
            label="Dichiaro di aver letto il <a href='../../../assets/documents/general_conditions.pdf' target='_blank'>Set Informativo</a> e i documenti Informativa sul distributore (<a href='../../../assets/documents/allegato_3.pdf' target='_blank'>Allegato 3</a>), Informazioni sulla distribuzione del prodotto assicurativo non IBIP (<a href='../../../assets/documents/allegato_4.pdf' target='_blank'>Allegato 4</a>). Elenco delle regole di comportamento del distributore (<a href='../../../assets/documents/allegato_4ter.pdf' target='_blank'>Allegato 4-ter</a>), previsti dal Regolamento IVASS n40 del 2/8/2018."
          ></app-toggle>

          <app-toggle
            *ngIf="this.pet.prezzoAumentato == 1"
            [(ngModel)]="account.insureConfirmation"
            name="insureConfirmation"
            [required]="true"
            [error]="accountErrors.insureConfirmation"
            label="Dichiaro di essere interessato ad assicurare per un anno il mio animale domestico in ambito privato per le coperture assicurative di assistenza, tutela legale e perdite pecuniarie per spese veterinarie/funerarie. Al click si avvierà il download del questionario Demands&Needs per permetterne al visualizzazione."
          ></app-toggle>

          <app-toggle
            *ngIf="this.pet.prezzoAumentato == 1"
            [(ngModel)]="account.infoSetConfirmation"
            name="infoSetConfirmation"
            [required]="true"
            [error]="accountErrors.infoSetConfirmation"
            label="Cliccando questa casella viene confermata al lettura del Set Informativo e del questionario Demands&Needs e la volontà di concludere li contratto."
          ></app-toggle>

          <app-toggle
            *ngIf="this.pet.prezzoAumentato == 1"
            [(ngModel)]="account.infoEmailConfirmation"
            name="infoEmailConfirmation"
            [required]="true"
            [error]="accountErrors.infoEmailConfirmation"
            label="Acconsento a ricevere la documentazione di polizza via e-mail e non in formato cartaceo"
          ></app-toggle>

          <div>
            <button (click)="nextStep()">Vai al pagamento</button>
          </div>

          <h5>Hai un codice promozionale? Inseriscilo nel prossimo step</h5>
          
        </div>

      </div>
      <div *ngIf="step == 9" id="step-10">
        <!--finito-->
        <div class="top-logo">
          <img [attr.src]="'../assets/svg/check.svg'" alt="check Image" />
        </div>
        <h2>
          Congratulazioni!
          {{ pet.name.charAt(0).toUpperCase() + pet.name.slice(1) }} è
          assicurato con il pacchetto {{ pet.insurance.nomeAssicurazione }}!
        </h2>
        <p>
          Riceverai un'email di conferma con tutti i dettagli della tua
          assicurazione
        </p>
        <button (click)="assureAnotherPet()">Assicura un altro Pet</button>
      </div>
    </div>
  </div>
</main>
<!-- Footer bar -->
<div class="footer-bar" *ngIf="step == 4 && !isModal">
  <p class="title gradient">{{ pet.insurance.nomeAssicurazione }}</p>

  <div class="footer-text">
    <span style="font-size: var(--font-l)" class="gradient"
      >{{
          pet.insurance.prezzo.split(".")[0] +
          "," +
          pet.insurance.prezzo.split(".")[1]
      }}
      €</span
    >
    <button class="button-filled" (click)="nextStep()">
      Proteggi {{ pet.name.charAt(0).toUpperCase() + pet.name.slice(1) }}
    </button>
  </div>
</div>

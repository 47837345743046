import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgFor } from '@angular/common';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-segment',
  standalone: true,
  imports: [NgFor,CommonModule],
  templateUrl: './segment.component.html',
  styleUrls: ['./segment.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SegmentComponent),
      multi: true,
    },
  ],
})
export class SegmentComponent implements ControlValueAccessor {
  @Input() options: any = [];
  @Input() style: string = 'md';

  valoreInterno: string = '';
  onChange = (value: any) => {};
  onTouched = () => {};

  writeValue(obj: any): void {
    this.valoreInterno = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onValoreInternoChange(value: string) {
    this.valoreInterno = value;
    this.onChange(value);
  }
}

import { Component, ViewChild } from '@angular/core';
import { InputComponent } from '../components/input/input.component';
import { SelectComponent } from '../components/select/select.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ToggleComponent } from '../components/toggle/toggle.component';
import { DialogComponent } from '../components/dialog/dialog.component';
import { RouterModule, ActivatedRoute, Router } from '@angular/router';
import { LogoComponent } from '../components/logo/logo.component';
import { environment } from '../../environments/environment';
import { StripeService } from 'ngx-stripe';

import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-insure',
  standalone: true,
  templateUrl: './insure.component.html',
  styleUrl: './insure.component.scss',
  imports: [
    InputComponent,
    RouterModule,
    LogoComponent,
    SelectComponent,
    FormsModule,
    CommonModule,
    ToggleComponent,
    DialogComponent,
  ],
})
export class InsureComponent {
  token: string = '';
  step: number = 0;

  insurances: any = [];
  originalInsurancePrices: any = [];

  idContratto: string = '';

  races: any = [];
  sizes: any = [];

  species: any = [];

  insuranceServices: any[] = [];

  //date per le validazioni
  minAccountBirthDate = new Date(
    new Date().setFullYear(new Date().getFullYear() - 18)
  )
    .toISOString()
    .split('T')[0];
  today = new Date().toISOString().split('T')[0];

  isMobile: boolean = false; //per scegliere i contenuti da visualizzare

  cardColors = [
    { titolo: 'MyVet Blue', img: '../../assets/img/pet-cards/blue.png' },
    { titolo: 'Mix', img: '../../assets/img/pet-cards/mix.png' },
    { titolo: 'Paw pink', img: '../../assets/img/pet-cards/paw-pink.png' },
  ];

  genders: any = [
    { nome: 'Maschio', value: 'M' },
    { nome: 'Femmina', value: 'F' },
  ];

  petAges: any = [
    { label: '0-11 mesi', value: '0-11' },
    { label: '1 anno', value: '1' },
    { label: '2 anni', value: '2' },
    { label: '3 anni', value: '3' },
    { label: '4 anni', value: '4' },
    { label: '5 anni', value: '5' },
    { label: '6 anni', value: '6' },
    { label: '7 anni', value: '7' },
    { label: '8 anni', value: '8' },
    { label: '9 anni', value: '9' },
    { label: '10 anni', value: '10' },
  ];

  documenti: any = [
    { label: "carta d'identità", value: '1' },
    { label: 'patente', value: '2' },
    { label: 'passaporto', value: '3' },
  ];

  account = {
    idUtente: 0,
    idUtenteStripe: 0,
    name: '',
    surname: '',
    email: '',
    confermaEmail: '',
    telephone: '',
    cf: '',
    luogoNascita: '',
    statoNascita: '',
    dataNascita: '',
    address: '',
    city: '',
    cap: '',
    documento: { label: '', value: '' },
    numeroDoc: '',
    dataScadenza: '',
    newsletter: false,
    tos: false,
    general: false,
    insureConfirmation: false,
    infoSetConfirmation: false,
    infoEmailConfirmation: false,
  };

  accountErrors: any = {
    name: '',
    surname: '',
    telephone: '',
    email: '',
    confermaEmail: '',
    cf: '',
    luogoNascita: '',
    statoNascita: '',
    dataNascita: '',
    address: '',
    city: '',
    cap: '',
    documento: '',
    numeroDoc: '',
    dataScadenza: '',
    tos: '',
    general: '',
    insureConfirmation: '',
    infoSetConfirmation: '',
    infoEmailConfirmation: '',
  };

  pet: any = {
    idAnimale: 0,
    idSpecie: '',
    specie: '',
    name: '',
    age: '',
    macroRace: 'meticcio', //meticcio o di razza
    race: { label: '', value: '' }, //select delle razze
    size: '', //piccola media grande
    gender: '', //maschio o femmina
    insurance: { nome: '', id: 0, prezzo: 0 },
    microchip: '',
    dataMicrochip: '',
    tatuaggio: '',
    dataTatuaggio: '',
    birthDate: '',
    card: false,
    cardColor: '',
    veterinarioCode: '',
    prezzoAumentato: false,
  };

  petErrors: any = {
    insurance: false,
    dataNascita: '',
    setIdentificativo: false,
    microchip: '',
    dataMicrochip: '',
    tatuagio: '',
    dataTatuagio: '',
  };

  totalPrice: string = '';

  optionsOpen: boolean = false;
  setMicrochip: number = 0; //0 vuoto 1 si 2 no

  isModal: boolean = false; //gestore modal di pacchetti assicurazioni
  isModal2: boolean = false; //gestore modal di servizi di assicurazioni
  isModal3: boolean = false; //gestore modal di pacchetto prevenzione
  isModal4: boolean = false; //gestore modal di pacchetto prevenzione maggiori info

  modalText: any[] = [''];
  modal2Text: any[] = [''];
  modal3Text: any[] = [''];
  modal4Text: string = '';

  private unsubscriber: Subject<void> = new Subject<void>();
  @ViewChild('show', { static: false }) show!: DialogComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private stripeService: StripeService
  ) {}

  async ngOnInit() {
    window.scrollTo({ top: 0 });
    this.isMobile = false;

    this.route.params.subscribe(async (params) => {
      this.step = Number(params['step']);
    });

    history.pushState(null, '');

    fromEvent(window, 'popstate')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((_) => {
        if (this.step == 4) {
          history.pushState(null, '');
          this.show.showModal();
        } else {
          this.backStep();
        }
      });

    try {
      this.pet.idAnimale = JSON.parse(localStorage.getItem('idPet') ?? '');
      this.account.idUtente = JSON.parse(
        localStorage.getItem('idAccount') ?? ''
      );
      this.idContratto = JSON.parse(localStorage.getItem('idContratto') ?? '');

      if (window.screen.availWidth < 768) {
        this.isMobile = true;
      }

      await this.getSpecies();
      await this.getAnimal();
      await this.getRaces();
      await this.getUser();
      await this.getInsurances();
      await this.getContract();
      await this.getInsuranceServices();

      if (this.pet.microchip) {
        this.setMicrochip = 1;
      }

      if (this.pet.prezzoAumentato) {
        this.aumentareTotale();
      }

      //se è visualizzazione da telefono imposto già i prezzi aumentati
      if (!this.pet.prezzoAumentato && this.isMobile) {
        this.pet.prezzoAumentato = true;
        this.aumentareTotale();
      }
    } catch (e) {
      this.pet = JSON.parse('{}');
      this.account = JSON.parse('{}');

      this.router.navigate(['/home']);
    }
  }

  /**
   * funzione che gestisce la risposta del modal per tornare alla pagina home
   * @param response
   */
  modalResponse(response: any) {
    if (response) {
      this.router.navigate(['/home']);
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  async selectInsurance(selectedInsurance: any) {
    this.pet.insurance = { ...selectedInsurance };
    this.petErrors.insurance = false;

    await this.getInsuranceServices();
  }

  aumentareTotale() {
    if (this.originalInsurancePrices.length === 0) {
      this.originalInsurancePrices = this.insurances.map((insurance: any) => ({
        ...insurance,
      }));
    }

    if (this.pet.prezzoAumentato) {
      this.insurances.forEach((insurance: any) => {
        insurance.prezzo =
          Number(insurance.prezzo) + Number(insurance.prezzoChirurgia);
        insurance.prezzo = insurance.prezzo.toFixed(2);
        console.log(insurance.prezzo);
      });
    } else {
      this.insurances = this.originalInsurancePrices.map((insurance: any) => ({
        ...insurance,
      }));
    }

    const selectedInsurance = this.insurances.find(
      (insurance: any) =>
        insurance.idAssicurazione === this.pet.insurance.idAssicurazione
    );
    if (selectedInsurance) {
      this.pet.insurance.prezzo = selectedInsurance.prezzo;
    }
  }

  setModal(txt: any[]) {
    this.isModal = txt && txt.length > 0;

    if (this.isModal) {
      this.modalText = txt;
    }
  }

  setModalServices(txt: any[]) {
    this.isModal2 = txt && txt.length > 0;
    this.modal2Text = txt;
  }

  setModal3(txt: any[]) {
    this.isModal3 = txt && txt.length > 0;
    this.modal3Text = txt;
  }

  setModal4(txt: string = '') {
    this.isModal4 = txt !== '';
    this.modal4Text = txt;
  }

  setStep(step: number) {
    this.step = step;

    //aggiorno l'url senza riaggiornare la pagina
    window.history.pushState({}, '', `/insure/${step}`);
  }

  backStep() {
    this.setStep(--this.step);
  }

  async nextStep() {
    switch (this.step) {
      case 4:
        if (this.validateInsurance()) {
          await this.updateContract();
          this.setStep(++this.step);
        }
        break;
      case 5:
        if (this.validateForm()) {
          await this.updateAnimal();
          this.setStep(++this.step);
        }
        break;
      case 6:
        if (await this.validateInfoCliente()) {
          await this.updateUser();
          this.setStep(++this.step);
        }
        break;
      case 7:
        if (await this.validateInfoAnimale()) {
          await this.updateAnimal();
          this.setStep(++this.step);
        }
        break;
      case 8:
        this.validateRequiredToggle();
        if (this.pet.prezzoAumentato == 1) {
          this.validateRequiredToggleGen();
          this.validateRequiredToggleInsureConfirm();
          this.validateRequiredToggleInfoSet();
          this.validateRequiredToggleEmailConfirm();
        }

        if (
          !this.accountErrors.tos &&
          !this.accountErrors.general &&
          !this.accountErrors.insureConfirmation &&
          !this.accountErrors.infoSetConfirmation &&
          !this.accountErrors.infoEmailConfirmation
        ) {
          await this.updateContract();
          await this.redirectToPayment();
        }
        break;
      case 9:
        this.setStep(++this.step);
        break;
    }

    //riporta in cima alla pagina
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  async redirectToPayment() {
    const response = await fetch(
      environment.apiUrl +
        environment.apiVersion +
        '/animals/' +
        this.pet.idAnimale +
        '/contracts/' +
        this.idContratto +
        '/checkout',
      {
        method: 'POST',
      }
    );

    const data = await response.json();

    if (!response.ok) {
      throw new Error(`${data.description}`);
    }

    this.stripeService
      .redirectToCheckout({ sessionId: data.body.idStripeSession })
      .subscribe((result) => {
        if (result.error) {
          alert(result.error.message);
        }
      });
  }

  validateForm() {
    this.validateMicrochip();
    this.validateMicrochipDate();
    this.validateTatuaggio();
    this.validateTatuaggioDate();
    this.validateDate();
    if (this.setMicrochip === 0) {
      this.petErrors.setIdentificativo = true;
      return false;
    }
    if (this.setMicrochip === 1) {
      return (
        !this.petErrors.dataNascita &&
        !this.petErrors.microchip &&
        !this.petErrors.dataMicrochip
      );
    } else if (this.setMicrochip === 2) {
      return (
        !this.petErrors.dataNascita &&
        !this.petErrors.tatuaggio &&
        !this.petErrors.dataTatuaggio
      );
    }

    return false;
  }

  setSelect(x: number) {
    this.petErrors.setIdentificativo = false;
    this.petErrors.microchip = false;
    this.petErrors.dataMicrochip = false;
    this.petErrors.tatuaggio = false;
    this.petErrors.dataTatuaggio = false;

    this.optionsOpen = !this.optionsOpen;
    this.setMicrochip = x;
    if ((x = 2)) {
      this.pet.microchip = '';
      this.pet.dataMicrochip = '';
    }
    if ((x = 1)) {
      this.pet.tatuaggio = '';
      this.pet.dataTatuaggio = '';
    }
  }

  setRadio() {
    this.pet.card = !this.pet.card;
  }

  setCard(titolo: string) {
    this.pet.cardColor = titolo;
  }

  async selectSpecies(specie: any) {
    if (specie) {
      this.pet.specie = specie.nome;
      this.pet.idSpecie = specie.idSpecie;

      this.petErrors.specie = false;
    }

    if (this.pet.specie === 'Gatto') {
      this.pet.size = '';
    }

    await this.getRaces();
  }

  async selectGender(gender: any) {
    if (gender) {
      this.pet.gender = gender.value;
      this.petErrors.gender = false;
    }
  }

  async selectMacroRace(macroRace: string) {
    if (this.pet.specie == '') {
      this.petErrors.specie = true;
      this.petErrors.macroRace = true;
      return;
    }
    this.pet.macroRace = macroRace;

    if (this.pet.macroRace === 'meticcio') {
      await this.getSizes();

      this.races.forEach((race: any) => {
        if (race.label.toLowerCase() === 'meticcio') this.pet.race = race;
      });
    }

    if (this.pet.macroRace === 'di razza') {
      this.pet.size = null;
    }

    this.petErrors.specie = false;
    this.petErrors.macroRace = false;
  }

  selectRace() {
    if (this.pet.race.value) {
      this.races.forEach((razza: any) => {
        if (razza.value == this.pet.race.value)
          this.pet.race.label = razza.label;
      });
    }
  }

  selectSize(size: any) {
    this.pet.size = size.idTaglia;
    this.petErrors.size = false;
  }

  setPetAge() {
    let currentYear = new Date().getFullYear();
    let dogYear = this.pet.dataNascita.split('-')[0];
    let years = currentYear - dogYear;

    if (years == 0) this.pet.age = '0-11';

    this.petAges.forEach((eta: any) => {
      if (years == eta.value) this.pet.age = eta.value;
    });
  }

  //##### get section

  async getRaces() {
    try {
      const IDSpecie = this.pet.idSpecie;
      const response = await fetch(
        environment.apiUrl +
          environment.apiVersion +
          '/animals/species/' +
          IDSpecie +
          '/races'
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(`${data.description}`);
      }
      this.races = data.body.map((race: { idRazza: any; nome: any }) => ({
        value: race.idRazza,
        label: race.nome,
      }));

      if (this.pet.idRazza) {
        const selectedRace = this.races.find(
          (race: { value: Number }) => race.value == this.pet.idRazza
        );

        if (selectedRace) {
          this.pet.race = selectedRace;
          this.pet.macroRace =
            selectedRace.label == 'Meticcio' ? 'meticcio' : 'di razza';
        }
      }

      if (this.pet.macroRace === 'meticcio') {
        await this.getSizes();
      }

      if (this.pet.macroRace === 'di razza') {
        this.pet.size = null;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async getSizes() {
    try {
      const response = await fetch(
        environment.apiUrl + environment.apiVersion + '/animals/sizes'
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(`${data.description}`);
      }

      this.sizes = data.body.map((size: any) => {
        return {
          idTaglia: size.idTaglia,
          nome: size.nome,
          pesoMinimo: size.pesoMinimo,
          pesoMassimo: size.pesoMassimo,
        };
      });
    } catch (error) {
      console.log(error);
    }
  }

  async getSpecies() {
    try {
      const response = await fetch(
        environment.apiUrl + environment.apiVersion + '/animals/species'
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(`${data.description}`);
      }

      this.species = data.body;
    } catch (error) {
      console.log(error);
    }
  }

  async getContract() {
    try {
      const response = await fetch(
        environment.apiUrl +
          environment.apiVersion +
          '/animals/' +
          this.pet.idAnimale +
          '/contracts/' +
          this.idContratto
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(`${data.description}`);
      }

      let contract = data.body;

      //assegno l'assicurazione corrispondente a quella scelta
      this.pet.insurance = this.insurances.find(
        (assicurazione: any) =>
          assicurazione.idAssicurazione == contract.idAssicurazione
      );
      this.pet.prezzoAumentato =
        contract.copertureAssicurative == 0 ? false : true;
    } catch (error) {
      console.log(error);
    }
  }

  async getInsurances() {
    try {
      const response = await fetch(
        environment.apiUrl + environment.apiVersion + '/insurances'
      );
      const data = await response.json();

      if (!response.ok) {
        throw new Error(`${data.description}`);
      }

      this.insurances = data.body.map(
        (insurance: {
          descrizione: any;
          clausola: string;
          idAssicurazione: any;
          nomeAssicurazione: any;
          nomeSpecie: any;
          prezzo: any;
          prezzoChirurgia: any;
        }) => {
          let opzioniDescrizione = insurance.descrizione.split('<mobile>');
          let descrizioneArray = [];

          for (let e = 0; e < opzioniDescrizione.length; e++) {
            descrizioneArray[e] = opzioniDescrizione[e].split('<opt>');

            for (let i = 0; i < descrizioneArray[e].length; i++) {
              if (descrizioneArray[e][i].includes('<icon>')) {
                descrizioneArray[e][i] = descrizioneArray[e][i].split('<icon>');
              }
              if (descrizioneArray[e][i].includes('<subtitle>')) {
                descrizioneArray[e][i] = descrizioneArray[e][i].replace(
                  '<subtitle>',
                  ''
                );
              }
            }
          }

          const clausola = insurance.clausola || '';
          const sections = clausola.split('<text>');

          const structuredData = sections.map((section: string) => {
            const [title, text] = section.split('<title>');
            const trimmedTitle = title ? title.trim() : '';
            const items = text
              ? text.split('\\n').map((item: string) => item.trim())
              : [];
            return { title: trimmedTitle, items };
          });

          const flattenedStructuredData = ([] as any[]).concat(
            ...structuredData
          );
          return {
            clausola: flattenedStructuredData,
            descrizione: this.isMobile
              ? descrizioneArray[1]
              : descrizioneArray[0],
            idAssicurazione: insurance.idAssicurazione,
            nomeAssicurazione: insurance.nomeAssicurazione,
            nomeSpecie: insurance.nomeSpecie,
            prezzo: Number(insurance.prezzo).toFixed(2),
            prezzoChirurgia: insurance.prezzoChirurgia,
          };
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  async getInsuranceServices() {
    const idInsurance = this.pet.insurance.idAssicurazione;
    try {
      const response = await fetch(
        environment.apiUrl +
          environment.apiVersion +
          '/insurances/' +
          idInsurance +
          '/services'
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(`${data.description}`);
      }

      this.insuranceServices = data.body.map((service: any) => {
        const services = service.clausola
          .split('\\n')
          .filter((item: string) => item.trim() !== '');

        return {
          clausola: services,
          descrizione: service.descrizione,
          idAssicurazione: service.idAssicurazione,
          idServizio: service.idServizio,
          nome: service.nome,
          prezzo: service.prezzo,
        };
      });

      return this.insuranceServices;
    } catch (error) {
      console.log(error);

      throw error;
    }
  }

  async getAnimal() {
    try {
      const IDAnimale = this.pet.idAnimale;
      const response = await fetch(
        environment.apiUrl + environment.apiVersion + '/animals/' + IDAnimale
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(`${data.description}`);
      }

      let pet = data.body;

      this.pet.idRazza = pet.idRazza;
      this.pet.idSpecie = pet.idSpecie;
      this.pet.size = pet.idTaglia;
      this.pet.name = pet.nome;
      this.pet.dataNascita = pet.dataNascita;
      this.pet.gender = pet.sesso;

      if (pet.numeroIdentificativo) {
        if (pet.numeroIdentificativo.length >= 15) {
          this.pet.microchip = pet.numeroIdentificativo;
          this.pet.dataMicrochip = pet.dataIdentificativo;
        } else {
          this.pet.tatuaggio = pet.numeroIdentificativo;
          this.pet.dataTatuaggio = pet.dataIdentificativo;
        }
      }
      this.pet.numeroIdentificativo = pet.numeroIdentificativo;

      //imposto il nome della specie
      this.species.forEach((specie: any) => {
        if (specie.idSpecie == this.pet.idSpecie) this.pet.specie = specie.nome;
      });

      //imposto l'età
      this.setPetAge();

      //imposto se microchip o tatuaggio
      if (this.pet.numeroIdentificativo) {
        this.setMicrochip = this.pet.numeroIdentificativo.length >= 15 ? 1 : 2;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async getUser() {
    try {
      const IDUtente = this.account.idUtente;
      const response = await fetch(
        environment.apiUrl + environment.apiVersion + '/users/' + IDUtente
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(`${data.description}`);
      }

      let account = data.body;

      this.account.name = account.nome;
      this.account.surname = account.cognome;
      this.account.email = account.email;
      this.account.telephone = account.telefono;
      this.account.confermaEmail = account.email;
      this.account.cf = account.codiceFiscale;
      this.account.luogoNascita = account.luogoNascita;
      this.account.statoNascita = account.statoNascita;
      this.account.dataNascita = account.dataNascita
        ? account.dataNascita.split(' ')[0]
        : '';
      this.account.address = account.indirizzoResidenza;
      this.account.city = account.cittaResidenza;
      this.account.cap = account.capResidenza;
      this.account.documento = account.idTipoDocumento;
      this.account.numeroDoc = account.numeroDocumento;
      this.account.dataScadenza = account.dataScadenzaDocumento
        ? account.dataScadenzaDocumento.split(' ')[0]
        : '';
      this.account.tos = false;
      this.account.general = false;
      this.account.insureConfirmation = false;
      this.account.infoSetConfirmation = false;
      this.account.infoEmailConfirmation = false;
    } catch (error) {
      console.log(error);
    }
  }

  //##### post section

  async updateContract() {
    let params = JSON.stringify({
      idContratto: this.idContratto,
      idAssicurazione: this.pet.insurance.idAssicurazione,
      copertureAssicurative: this.pet.prezzoAumentato,
      codiceAffiliato: this.pet.veterinarioCode,
    });

    try {
      const response = await fetch(
        environment.apiUrl +
          environment.apiVersion +
          '/animals/' +
          this.pet.idAnimale +
          '/contracts/' +
          this.idContratto,
        {
          method: 'PUT',
          body: params,
        }
      );

      let data = await response.json();

      if (!response.ok) {
        throw new Error(`${data.description}`);
      }
    } catch (error) {
      throw new Error('Impossibile aggiornare il contratto');
    }
  }

  async updateUser() {
    let params = JSON.stringify({
      IDUtenteStripe: this.account.idUtenteStripe,
      nome: this.account.name,
      cognome: this.account.surname,
      email: this.account.email,
      codiceFiscale: this.account.cf,
      telefono: this.account.telephone,
      luogoNascita: this.account.luogoNascita,
      statoNascita: this.account.statoNascita,
      dataNascita: this.account.dataNascita,
      indirizzoResidenza: this.account.address,
      cittaResidenza: this.account.city,
      capResidenza: this.account.cap,
      tipoDocumento: this.account.documento,
      numeroDocumento: this.account.numeroDoc,
      dataScadenzaDocumento: this.account.dataScadenza,
      privacy: this.account.tos,
      condizioniGenerali: this.account.general,
    });

    try {
      const response = await fetch(
        environment.apiUrl +
          environment.apiVersion +
          '/users/' +
          this.account.idUtente,
        {
          method: 'PUT',
          body: params,
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(`${data.description}`);
      }
    } catch (error) {
      throw new Error("Impossibile creare l'utente");
    }
  }

  async updateAnimal() {
    let params = JSON.stringify({
      nome: this.pet.name,
      idTaglia: this.pet.size,
      idRazza: this.pet.race.value,
      sesso: this.pet.gender,
      idUtente: this.account.idUtente,
      identificativo: this.pet.microchip ?? this.pet.tatuaggio ?? null,
      dataIdentificativo:
        this.pet.dataMicrochip ?? this.pet.dataTatuaggio ?? null,
      dataNascita: this.pet.dataNascita,
    });

    try {
      const response = await fetch(
        environment.apiUrl +
          environment.apiVersion +
          '/animals/' +
          this.pet.idAnimale,
        {
          method: 'PUT',
          body: params,
        }
      );

      let data = await response.json();

      if (!response.ok) {
        throw new Error(`${data.description}`);
      }
    } catch (error) {
      throw new Error("Impossibile creare l'animale");
    }
  }

  //##### validation section

  validateSpecies() {
    if (!this.pet.specie) {
      this.petErrors.specie = true;
      return false;
    }
    this.petErrors.specie = false;
    return true;
  }

  validateGender() {
    if (!this.pet.gender) {
      this.petErrors.gender = true;
      return false;
    }
    this.petErrors.gender = false;
    return true;
  }

  validatePetName() {
    this.petErrors.name = '';

    if (!this.pet.name) {
      this.petErrors.name += 'Il campo non può essere vuoto \n';
      return false;
    }
    return true;
  }

  validateAge() {
    this.petErrors.age = '';

    if (!this.pet.age) {
      this.petErrors.age += 'Il campo non può essere vuoto \n';
      return false;
    }
    return true;
  }

  validateMacroRace() {
    if (!this.pet.macroRace) {
      this.petErrors.macroRace = true;
      return false;
    }
    this.petErrors.macroRace = false;
    return true;
  }

  validateRace() {
    this.petErrors.race = '';

    if (!this.pet.race.value) {
      this.petErrors.race = 'Il campo non può essere vuoto \n';
      return false;
    }

    return true;
  }

  validateSize() {
    if (
      !this.pet.size &&
      this.pet.macroRace === 'meticcio' &&
      this.pet.specie === 'Cane'
    ) {
      this.petErrors.size = true;
      return false;
    }
    this.petErrors.size = false;
    return true;
  }

  validateMicrochip() {
    this.petErrors.microchip = '';

    // Rimuoviamo gli spazi dall'inizio e dalla fine

    // Verifica che la stringa non sia vuota dopo aver rimosso gli spazi
    if (!this.pet.microchip) {
      this.petErrors.microchip += 'Il campo non può essere vuoto \n';
      return false;
    } else {
      const trimmedMicrochip = this.pet.microchip.trim();
      // Espressione regolare che controlla lettere (anche accentate), spazi, apostrofi e trattini
      if (!/^\d{15}$/.test(trimmedMicrochip)) {
        this.petErrors.microchip +=
          'Deve essere composto da 15 cifre numeriche';
        return false;
      }
    }
    return true;
  }

  validateMicrochipDate() {
    this.petErrors.dataMicrochip = '';

    if (!this.pet.dataMicrochip) {
      this.petErrors.dataMicrochip += 'Il campo non può essere vuoto \n';
    }
  }

  validateTatuaggio() {
    this.petErrors.tatuaggio = '';

    const trimmedTatuaggio = this.pet.tatuaggio.trim().toUpperCase();

    // Check if the string is empty after removing spaces
    if (!trimmedTatuaggio) {
      this.petErrors.tatuaggio += 'Il campo non può essere vuoto \n';
      return false;
    } else {
      if (!/^[A-Z]\d{3}\d{2}[A-Z]{2}\d{4}$/i.test(trimmedTatuaggio)) {
        this.petErrors.tatuaggio +=
          'Il formato deve essere: 1 lettera, 3 cifre, 2 cifre, 2 lettere, 4 cifre';
        return false;
      }
    }
    return true;
  }

  validateTatuaggioDate() {
    this.petErrors.dataTatuaggio = '';

    if (!this.pet.dataTatuaggio) {
      this.petErrors.dataTatuaggio += 'Il campo non può essere vuoto \n';
    }
  }

  validateDate() {
    this.petErrors.dataNascita = '';

    if (!this.pet.dataNascita) {
      this.petErrors.dataNascita += 'Il campo non può essere vuoto \n';
      return;
    }

    if (this.pet.dataTatuaggio) {
      if (new Date(this.pet.dataNascita) > new Date(this.pet.dataTatuaggio)) {
        this.petErrors.dataNascita +=
          'La data non può essere superiore alla data di inserimento del tatuaggio \n';
        return;
      }
    }

    if (this.pet.dataMicrochip) {
      if (new Date(this.pet.dataNascita) > new Date(this.pet.dataMicrochip)) {
        this.petErrors.dataNascita +=
          'La data non può essere superiore alla data di inserimento del microchip \n';
        return;
      }
    }

    this.setPetAge();
  }

  validateInsurance() {
    if (!this.pet.insurance) {
      this.petErrors.insurance = true;
      return false;
    }
    this.petErrors.insurance = false;
    return true;
  }

  validateName() {
    this.accountErrors.name = '';

    // Rimuoviamo gli spazi dall'inizio e dalla fine
    const trimmedName = this.account.name.trim();

    // Verifica che la stringa non sia vuota dopo aver rimosso gli spazi
    if (!trimmedName) {
      this.accountErrors.name += 'Il campo non può essere vuoto \n';
      return false;
    } else {
      // Espressione regolare che controlla lettere (anche accentate), spazi, apostrofi e trattini
      if (!/^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/.test(trimmedName)) {
        this.accountErrors.name +=
          'Possono essere usate solo lettere, apostrofi e trattini';
        return false;
      }
    }
    return true;
  }

  validateSurname() {
    this.accountErrors.surname = '';

    // Rimuoviamo gli spazi dall'inizio e dalla fine
    const trimmedSurname = this.account.surname.trim();

    // Verifica che la stringa non sia vuota dopo aver rimosso gli spazi
    if (!trimmedSurname) {
      this.accountErrors.surname += 'Il campo non può essere vuoto \n';
      return false;
    } else {
      // Espressione regolare che controlla lettere (anche accentate), spazi, apostrofi e trattini
      if (!/^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/.test(trimmedSurname)) {
        this.accountErrors.surname +=
          'Possono essere usate solo lettere, apostrofi e trattini';
        return false;
      }
    }
    return true;
  }

  validateTelephone() {
    this.accountErrors.telephone = '';
    const trimmedTelephone = this.account.telephone.trim();

    if(!trimmedTelephone){
      this.accountErrors.telephone = 'Il campo non può essere vuoto';
      return false;
    } else if(!/^\d{9,15}$/.test(trimmedTelephone.replace(/[\s()+\-]/g, ''))) {
      // Espressione regolare per il formato base del codice fiscale
      this.accountErrors.telephone = 'Numero di Telefono non valido';
      return false;
    }
    return true;
  }

  async validateEmail() {
    this.accountErrors.email = '';
    const trimmedEmail = this.account.email.trim();

    // Validità di un'email
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(trimmedEmail)) {
      this.accountErrors.email = 'Inserire una mail valida';
      return false;
    }

    return true;
  }

  validateConfermaEmail() {
    this.accountErrors.confermaEmail = '';

    if (!this.account.confermaEmail) {
      this.accountErrors.confermaEmail = 'Il campo non può essere vuoto';
      return false;
    } else {
      const trimmedOriginalEmail = this.account.email.trim().toLowerCase();
      const trimmedConfermaEmail = this.account.confermaEmail
        .trim()
        .toLowerCase();

      if (trimmedConfermaEmail !== trimmedOriginalEmail) {
        this.accountErrors.confermaEmail = 'Le email non corrispondono';
        return false;
      }

      return true;
    }
  }

  validateCF() {
    this.accountErrors.cf = '';

    if (!this.account.cf) {
      this.accountErrors.cf = 'Il campo non può essere vuoto \n';
    } else if (
      // Espressione regolare per il formato base del codice fiscale
      !/^[A-Z]{6}[0-9]{2}[A-Z]{1}[0-9]{2}[A-Z]{1}[0-9]{2}[0-9A-Z]{1}[A-Z]{1}?$/.test(
        this.account.cf.toUpperCase().trim()
      )
    ) {
      this.accountErrors.cf = 'Codice Fiscale non valido';
    }
  }

  validateLuogoNascita() {
    this.accountErrors.luogoNascita = '';

    if (!this.account.luogoNascita) {
      this.accountErrors.luogoNascita += 'Il campo non può essere vuoto \n';
    } else {
      const trimmedNome = this.account.luogoNascita.trim();
      if (!/^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/.test(trimmedNome)) {
        this.accountErrors.luogoNascita +=
          'Possono essere usate solo lettere, apostrofi e trattini';
      }
    }
  }

  validateStato() {
    this.accountErrors.statoNascita = '';

    if (!this.account.statoNascita) {
      this.accountErrors.statoNascita += 'Il campo non può essere vuoto \n';
    } else {
      const trimmedNome = this.account.statoNascita.trim();
      if (!/^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/.test(trimmedNome)) {
        this.accountErrors.statoNascita +=
          'Possono essere usate solo lettere, apostrofi e trattini';
      }
    }
  }

  validateDataNascita() {
    this.accountErrors.dataNascita = '';

    if (!this.account.dataNascita) {
      this.accountErrors.dataNascita += 'Il campo non può essere vuoto \n';
      return;
    }
  }

  validateAddress() {
    this.accountErrors.address = '';

    if (!this.account.address) {
      this.accountErrors.address += 'Il campo non può essere vuoto \n';
    } else {
      const trimmedAddress = this.account.address.trim();
      if (!/^[A-Za-z0-9À-ÖØ-öø-ÿ'\-,\. ]+$/.test(trimmedAddress)) {
        this.accountErrors.address +=
          'Inserire solo caratteri consoni alla composizione di un indirizzo valido';
      }
    }
  }

  validateCity() {
    this.accountErrors.city = '';

    if (!this.account.city) {
      this.accountErrors.city += 'Il campo non può essere vuoto \n';
    } else {
      const trimmedNome = this.account.city.trim();
      if (!/^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/.test(trimmedNome)) {
        this.accountErrors.city +=
          'Possono essere usate solo lettere, apostrofi e trattini';
      }
    }
  }

  validateCap() {
    this.accountErrors.cap = '';

    if (!this.account.cap) {
      this.accountErrors.cap += 'Il campo non può essere vuoto \n';
    } else {
      const trimmedNome = this.account.cap.trim();
      if (!/^\d{5}$/.test(trimmedNome)) {
        this.accountErrors.cap += 'Il CAP contiene solo 5 numeri';
      }
    }
  }

  validateDocumento() {
    this.accountErrors.documento = '';

    if (!this.account.documento) {
      this.accountErrors.documento = 'Il campo non può essere vuoto \n';
      return false;
    }
    return true;
  }

  validateNumeroDoc() {
    this.accountErrors.numeroDoc = '';

    if (!this.account.numeroDoc) {
      this.accountErrors.numeroDoc = 'Il campo non può essere vuoto \n';
      return false;
    }
    return true;
  }

  validateDataScadenza() {
    this.accountErrors.dataScadenza = '';

    if (!this.account.dataScadenza) {
      this.accountErrors.dataScadenza += 'Il campo non può essere vuoto \n';
    }
  }

  validateRequiredToggle() {
    this.accountErrors.tos = !this.account.tos;
  }

  validateRequiredToggleGen() {
    this.accountErrors.general = !this.account.general;
  }

  validateRequiredToggleInsureConfirm() {
    this.accountErrors.insureConfirmation = !this.account.insureConfirmation;
  }

  validateRequiredToggleInfoSet() {
    this.accountErrors.infoSetConfirmation = !this.account.infoSetConfirmation;
  }

  validateRequiredToggleEmailConfirm() {
    this.accountErrors.infoEmailConfirmation =
      !this.account.infoEmailConfirmation;
  }

  async validateInfoCliente() {
    this.validateName();
    this.validateSurname();
    this.validateTelephone();
    await this.validateEmail();
    this.validateConfermaEmail();
    this.validateCF();
    this.validateLuogoNascita();
    this.validateCity();
    this.validateStato();
    this.validateDataNascita();
    this.validateAddress();
    this.validateCap();
    this.validateDocumento();
    this.validateNumeroDoc();
    this.validateDataScadenza();

    return (
      !this.accountErrors.name &&
      !this.accountErrors.surname &&
      !this.accountErrors.telephone &&
      !this.accountErrors.email &&
      !this.accountErrors.confermaMail &&
      !this.accountErrors.cf &&
      !this.accountErrors.luogoNascita &&
      !this.accountErrors.city &&
      !this.accountErrors.statoNascita &&
      !this.accountErrors.dataNascita &&
      !this.accountErrors.address &&
      !this.accountErrors.cap &&
      !this.accountErrors.documento &&
      !this.accountErrors.numeroDoc &&
      !this.accountErrors.dataScadenza
    );
  }

  async validateInfoAnimale() {
    this.validatePetName();
    this.validateAge();
    this.validateSize();
    this.validateGender();
    this.validateRace();
    this.validateMacroRace();
    this.validateMicrochip();
    this.validateMicrochipDate();
    this.validateTatuaggio();
    this.validateTatuaggioDate();
    this.validateDate();

    return (
      (!this.petErrors.name &&
        !this.petErrors.gender &&
        !this.petErrors.age &&
        !this.petErrors.macroRace &&
        !this.petErrors.race &&
        !this.petErrors.size &&
        this.setMicrochip === 1 &&
        !this.petErrors.dataNascita &&
        !this.petErrors.microchip &&
        !this.petErrors.dataMicrochip) ||
      (this.setMicrochip === 2 &&
        !this.petErrors.dataNascita &&
        !this.petErrors.tatuaggio &&
        !this.petErrors.dataTatuaggio)
    );
  }

  assureAnotherPet() {
    const queryParams = { clear: 'clear' };

    this.router.navigate(['/registration/1'], { queryParams });
  }

  openPdfGeneralConditions() {
    window.open('../../assets/documents/general_conditions.pdf', '_blank');
  }
}

import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-tos',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './tos.component.html',
  styleUrl: './tos.component.scss'
})
export class TosComponent {

  async ngOnInit(){
    window.scrollTo({top: 0});
  }
}

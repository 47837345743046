import { Component } from '@angular/core';
import { InputComponent } from '../components/input/input.component';
import { SelectComponent } from '../components/select/select.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ToggleComponent } from '../components/toggle/toggle.component';
import { ActivatedRoute, Router } from '@angular/router';
import { LogoComponent } from '../components/logo/logo.component';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-registration',
  standalone: true,
  imports: [
    InputComponent,
    SelectComponent,
    FormsModule,
    CommonModule,
    ToggleComponent,
    LogoComponent,
  ],
  templateUrl: './registration.component.html',
  styleUrl: './registration.component.scss',
})
export class RegistrationComponent {
  token: string = '';

  species: any = [];

  races: any = [];
  sizes: any = [];

  petAges: any = [
    { label: '0-11 mesi', value: '0-11' },
    { label: '1 anno', value: '1' },
    { label: '2 anni', value: '2' },
    { label: '3 anni', value: '3' },
    { label: '4 anni', value: '4' },
    { label: '5 anni', value: '5' },
    { label: '6 anni', value: '6' },
    { label: '7 anni', value: '7' },
    { label: '8 anni', value: '8' },
    { label: '9 anni', value: '9' },
    { label: '10 anni', value: '10' },
  ];

  genders: any = [
    { nome: 'Maschio', value: 'M' },
    { nome: 'Femmina', value: 'F' },
  ];

  pet: any = {
    idSpecie: '',
    specie: '',
    gender: '',
    name: '',
    age: '',
    macroRace: '', //meticcio o di razza
    race: { label: '', value: null }, //select delle razze
    size: '', //piccola media grande,
    insurance: '',
    microchip: '',
    dataMicrochip: '',
    tatuaggio: '',
    dataTatuaggio: '',
    birthDate: '',
    card: false,
    cardColor: '',
    veterinarioCode: '',
    prezzoAumentato: false,
  };

  petErrors: any = {
    specie: '',
    gender: '',
    name: '',
    age: '',
    macroRace: '',
    race: '',
    size: '',
    insurance: false,
  };

  account: any = {
    name: '',
    surname: '',
    email: '',
    telephone: '',
    newsletter: false,
    tos: false,
  };

  accountErrors: any = {
    name: '',
    surname: '',
    email: '',
    telephone: '',
  };

  service: any; //funzioni aggiuntive all'insurance
  step: number = 0;

  constructor(private route: ActivatedRoute, private router: Router) {}

  async ngOnInit() {
    window.scrollTo({ top: 0 });

    this.route.params.subscribe(async (params) => {
      this.step = Number(params['step']);
    });

    this.route.queryParams.subscribe(async (params) => {
      if (params['clear']) {
        this.clearData();
        this.pet.name = params['petName'];

        //mi sembra che in questo caso non ci entri mai
        // } else if (params['autocompile'] == 'true') {

        //   console.log('entro in autocompile');
        //   this.token = params['token'];
        //   localStorage.setItem('jwt-myvetassicurazioni', this.token);
        //   //compila tutto
        //   this.router.navigate(['/insure']);
      } else {
        this.pet = JSON.parse(localStorage.getItem('pet') ?? '');
      }
    });

    await this.getSpecies();
    await this.getSizes();
  }

  /**
   * elimina tutti i dati
   */
  clearData() {
    localStorage.setItem('idAccount', '');
    localStorage.setItem('idPet', '');
    localStorage.setItem('idContratto', '');

    this.pet = {
      idSpecie: '',
      specie: '',
      gender: '',
      name: '',
      age: '',
      macroRace: '', //meticcio o di razza
      race: { label: '', value: null }, //select delle razze
      size: '', //piccola media grande,
      insurance: '',
      microchip: '',
      dataMicrochip: '',
      tatuaggio: '',
      dataTatuaggio: '',
      birthDate: '',
      card: false,
      cardColor: '',
      veterinarioCode: '',
      prezzoAumentato: false,
    };

    this.accountErrors = {
      name: '',
      surname: '',
      email: '',
      telephone: '',
      tos: '',
    };

    this.petErrors = {
      specie: '',
      gender: '',
      name: '',
      age: '',
      macroRace: '',
      race: '',
      size: '',
      insurance: false,
    };
  }

  async selectSpecies(specie: any) {
    if (specie) {
      this.pet.specie = specie.nome;
      this.pet.idSpecie = specie.idSpecie;

      this.petErrors.specie = false;
    }

    if (this.pet.specie === 'Gatto') {
      this.pet.size = '';
    }

    await this.getRaces();
  }

  async selectGender(gender: any) {
    if (gender) {
      this.pet.gender = gender.value;
      this.petErrors.gender = false;
    }
  }

  async selectMacroRace(macroRace: string) {
    if (this.pet.specie == '') {
      this.petErrors.specie = true;
      this.petErrors.macroRace = true;
      return;
    }
    this.pet.macroRace = macroRace;

    if (this.pet.macroRace === 'meticcio') {
      await this.getSizes();

      this.races.forEach((race: any) => {
        if (race.label.toLowerCase() === 'meticcio')
          this.pet.race.value = race.value;
      });
    }

    if (this.pet.macroRace === 'di razza') {
      this.pet.size = null;
    }

    this.petErrors.specie = false;
    this.petErrors.macroRace = false;
  }

  selectRace() {
    if (this.pet.race.value) {
      this.races.forEach((razza: any) => {
        if (razza.value == this.pet.race.value)
          this.pet.race.label = razza.label;
      });
    }
  }

  selectSize(size: any) {
    this.pet.size = size.idTaglia;
    this.petErrors.size = false;
  }

  // selectInsurance(selectedInsuranceId: number) {
  //   let selectedInsurance = null;

  //   for (let i = 0; i < this.insurances.length; i++) {
  //     if (this.insurances[i].idAssicurazione === selectedInsuranceId) {
  //       selectedInsurance = this.insurances[i];
  //       break;
  //     }
  //   }

  //   // Check if an insurance with the specified ID was found
  //   if (selectedInsurance !== null) {
  //     this.pet.insurance = selectedInsurance;
  //     this.petErrors.insurance = false;
  //   } else {
  //     // Handle the case when no insurance with the provided ID is found
  //     this.pet.insurance = null;
  //     this.petErrors.insurance = true;
  //   }
  // }

  selectService(service: string) {
    if (this.service) {
      this.service = null;
      return;
    }
    this.service = service;
  }

  setStep(step: number) {
    this.step = step;

    //aggiorno l'url senza riaggiornare la pagina
    window.history.pushState({}, '', `/registration/${step}`);
  }

  goToChiamatemi() {
    this.router.navigate(['/chiamatemi']);
  }

  backStep() {
    this.setStep(--this.step);
  }

  async nextStep() {
    switch (this.step) {
      case 1:
        if (await this.step1()) {
          if (this.token != '') this.router.navigate(['/insure']);
          this.setStep(++this.step);
        }
        break;
      // case 2:
      //   if (await this.step2()) {
      //     this.setStep(++this.step);
      //   }
      //   break;
      case 2:
        if (await this.step2()) {
          if (this.pet.specie === 'Cane') {
            try {
              await this.createUser();
              await this.createAnimal();
              await this.createContract();

              this.router.navigate(['/insure']);
            } catch (error) {
              alert('Invio non riuscito, impossibile continuare');
            }
          } else {
            this.router.navigate(['/showcase/'], {
              queryParams: { type: 'gatti' },
            });
          }
        }
        break;
    }

    //riporta in cima alla pagina
    window.scrollTo({ top: 0 });
  }

  async step1() {
    //await this.getInsurances();
    this.validateSpecies();
    this.validateGender();
    this.validatePetName();
    this.validateAge();
    this.validateMacroRace();
    this.validateSize();
    this.validateRace();

    return (
      !this.petErrors.specie &&
      !this.petErrors.gender &&
      !this.petErrors.name &&
      !this.petErrors.age &&
      !this.petErrors.macroRace &&
      !this.petErrors.race &&
      !this.petErrors.size
    );
  }

  // async step2() {
  //   await this.getInsurances();
  //   this.validateInsurance();

  //   return !this.petErrors.insurance;
  // }

  async step2() {
    this.validateName();
    this.validateSurname();
    await this.validateEmail();
    this.validateTelephone();

    return (
      !this.accountErrors.name &&
      !this.accountErrors.surname &&
      !this.accountErrors.email &&
      !this.accountErrors.telephone
    );
  }

  async createUser() {
    let params = JSON.stringify({
      nome: this.account.name,
      cognome: this.account.surname,
      email: this.account.email,
      telefono: this.account.telephone,
      privacy: this.account.tos,
    });

    try {
      const response = await fetch(
        environment.apiUrl + environment.apiVersion + '/users',
        {
          method: 'POST',
          body: params,
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(`${data.description}`);
      }

      this.account.idUtente = data.body.idUtente;

      //registra account
      localStorage.setItem('idAccount', JSON.stringify(this.account.idUtente));
    } catch (error) {
      throw new Error("Impossibile creare l'utente");
    }
  }

  async createAnimal() {
    let params = JSON.stringify({
      nome: this.pet.name,
      idTaglia: this.pet.size,
      idRazza: this.pet.race.value,
      sesso: this.pet.gender,
      idUtente: this.account.idUtente,
      dataNascita: this.pet.birthDate,
    });

    try {
      const response = await fetch(
        environment.apiUrl + environment.apiVersion + '/animals/',
        {
          method: 'POST',
          body: params,
        }
      );

      let data = await response.json();

      if (!response.ok) {
        throw new Error(`${data.description}`);
      }

      this.pet.idAnimale = data.body.idAnimal;
      //registra animale
      localStorage.setItem('idPet', JSON.stringify(this.pet.idAnimale));
    } catch (error) {
      throw new Error("Impossibile creare l'animale");
    }
  }

  async createContract() {
    let params = JSON.stringify({
      idAssicurazione: 3,
    });

    try {
      const response = await fetch(
        environment.apiUrl +
          environment.apiVersion +
          '/animals/' +
          this.pet.idAnimale +
          '/contracts',
        {
          method: 'POST',
          body: params,
        }
      );

      let data = await response.json();

      if (!response.ok) {
        throw new Error(`${data.description}`);
      }

      localStorage.setItem(
        'idContratto',
        JSON.stringify(data.body.idContratto)
      );
    } catch (error) {
      throw new Error('Impossibile completare il pagamento');
    }
  }

  async getSpecies() {
    try {
      const response = await fetch(
        environment.apiUrl + environment.apiVersion + '/animals/species'
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(`${data.description}`);
      }

      this.species = data.body;
    } catch (error) {
      console.log(error);
    }
  }

  async getRaces() {
    try {
      const IDSpecie = this.pet.idSpecie;
      const response = await fetch(
        environment.apiUrl +
          environment.apiVersion +
          '/animals/species/' +
          IDSpecie +
          '/races'
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(`${data.description}`);
      }

      this.races = data.body.map((race: { idRazza: any; nome: any }) => ({
        value: race.idRazza,
        label: race.nome,
      }));
    } catch (error) {
      console.log(error);
    }
  }

  async getSizes() {
    try {
      const response = await fetch(
        environment.apiUrl + environment.apiVersion + '/animals/sizes'
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(`${data.description}`);
      }

      this.sizes = data.body.map((size: any) => {
        return {
          idTaglia: size.idTaglia,
          nome: size.nome,
          pesoMinimo: size.pesoMinimo,
          pesoMassimo: size.pesoMassimo,
        };
      });
    } catch (error) {
      console.log(error);
    }
  }
//dovrebbe non servire più
  // async getInsurances() {
  //   const params = new URLSearchParams({ idSpecie: this.pet.idSpecie });
  //   try {
  //     const response = await fetch(
  //       environment.apiUrl +
  //         environment.apiVersion +
  //         '/insurances' +
  //         '?' +
  //         params
  //     );

  //     const data = await response.json();

  //     if (!response.ok) {
  //       throw new Error(`${data.description}`);
  //     }

  //     this.insurances = data.body.map((insurance: any) => {
  //       let percento, descrizioneRegistrazione;

  //       switch (insurance.nomeAssicurazione) {
  //         case 'MyVet Bronze':
  //           percento = 'Bronze';
  //           descrizioneRegistrazione =
  //             'Per non farti sorprendere dagli imprevisti';
  //           break;
  //         case 'MyVet Silver':
  //           percento = 'Silver';
  //           descrizioneRegistrazione = 'Per salvaguardare i tuoi risparmi';
  //           break;
  //         default:
  //           percento = 'Gold';
  //           descrizioneRegistrazione = 'Per non avere più preoccupazioni';
  //           break;
  //       }

  //       return {
  //         clausola: insurance.clausola,
  //         descrizione: insurance.descrizione,
  //         idAssicurazione: insurance.idAssicurazione,
  //         nomeAssicurazione: insurance.nomeAssicurazione,
  //         nomeSpecie: insurance.nomeSpecie,
  //         prezzo: insurance.prezzo,
  //         percento,
  //         descrizioneRegistrazione,
  //       };
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  //##### validation section
  validateSpecies() {
    if (!this.pet.specie) {
      this.petErrors.specie = true;
      return false;
    }
    this.petErrors.specie = false;
    return true;
  }

  validateGender() {
    if (!this.pet.gender) {
      this.petErrors.gender = true;
      return false;
    }
    this.petErrors.gender = false;
    return true;
  }

  validatePetName() {
    this.petErrors.name = '';

    if (!this.pet.name) {
      this.petErrors.name += 'Il campo non può essere vuoto \n';
      return false;
    }
    return true;
  }

  validateAge() {
    this.petErrors.age = '';

    if (!this.pet.age) {
      this.petErrors.age += 'Il campo non può essere vuoto \n';
      return false;
    }

    let yearOfDate =
      new Date().getFullYear() -
      Number(this.pet.age == '0-11' ? 0 : this.pet.age);
    this.pet.birthDate = `${yearOfDate}-01-01`;

    return true;
  }

  validateMacroRace() {
    if (!this.pet.macroRace) {
      this.petErrors.macroRace = true;
      return false;
    }
    this.petErrors.macroRace = false;
    return true;
  }

  validateRace() {
    this.petErrors.race = '';

    if (!this.pet.race.value) {
      this.petErrors.race = 'Il campo non può essere vuoto \n';
      return false;
    }

    return true;
  }

  validateSize() {
    if (
      !this.pet.size &&
      this.pet.macroRace === 'meticcio' &&
      this.pet.specie === 'Cane'
    ) {
      this.petErrors.size = true;
      return false;
    }
    this.petErrors.size = false;
    return true;
  }

  //pure
  // validateInsurance() {
  //   if (!this.pet.insurance) {
  //     this.petErrors.insurance = true;
  //     return false;
  //   }
  //   this.petErrors.insurance = false;
  //   return true;
  // }

  validateName() {
    this.accountErrors.name = '';

    // Rimuoviamo gli spazi dall'inizio e dalla fine
    const trimmedName = this.account.name.trim();

    // Verifica che la stringa non sia vuota dopo aver rimosso gli spazi
    if (!trimmedName) {
      this.accountErrors.name += 'Il campo non può essere vuoto \n';
      return false;
    } else {
      // Espressione regolare che controlla lettere (anche accentate), spazi, apostrofi e trattini
      if (!/^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/.test(trimmedName)) {
        this.accountErrors.name +=
          'Possono essere usate solo lettere, apostrofi e trattini';
        return false;
      }
    }
    return true;
  }

  validateSurname() {
    this.accountErrors.surname = '';

    // Rimuoviamo gli spazi dall'inizio e dalla fine
    const trimmedSurname = this.account.surname.trim();

    // Verifica che la stringa non sia vuota dopo aver rimosso gli spazi
    if (!trimmedSurname) {
      this.accountErrors.surname += 'Il campo non può essere vuoto \n';
      return false;
    } else {
      // Espressione regolare che controlla lettere (anche accentate), spazi, apostrofi e trattini
      if (!/^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/.test(trimmedSurname)) {
        this.accountErrors.surname +=
          'Possono essere usate solo lettere, apostrofi e trattini';
        return false;
      }
    }
    return true;
  }

  async validateEmail() {
    this.accountErrors.email = '';
    const trimmedEmail = this.account.email.trim();

    // Validità di un'email
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(trimmedEmail)) {
      this.accountErrors.email = 'Inserire una mail valida';
      return false;
    }

    return true;
  }

  validateTelephone() {
    this.accountErrors.telephone = '';
    const trimmedTelephone = this.account.telephone.trim();

    if(!trimmedTelephone){
      return true;
    }

    if(!/^\d{9,15}$/.test(trimmedTelephone.replace(/[\s()+\-]/g, ''))) {
      // Espressione regolare per il formato base del codice fiscale
      this.accountErrors.telephone = 'Numero di Telefono non valido';
      return false;
    }
    return true;
  }
}

<main *ngIf="type === 'cani'" id="cani">
  <div>
    <div id="row-1">
      <div>
        <h2 class="gradient">
          I nostri pacchetti di assistenza sanitaria per {{ type }}
        </h2>
        <p>
          MyVet offre pacchetti All Care (infortuni e malattie), che rimborsano
          fino al 75% delle spese veterinarie. La nostra formula di assistenza
          prevede anche un pacchetto prevenzione annuale che contribuisce al
          rimborso degli onorari medici in caso di: vaccinazioni, sverminazioni,
          sterilizzazioni o castrazioni, visite preventive e valutative
          comportamentali.
        </p>
      </div>

      <img src="../../assets/img/showcase/cane.png" alt="" />
    </div>
    <div id="row-2">
      <h2 class="gradient">I nostri pacchetti nel dettaglio</h2>
      <div class="swiper">
        <table>
          <tr>
            <th scope="col" colspan="2">
              <h4 class="gradient subtitle">LA MIA COPERTURA</h4>
            </th>
            <th scope="col"><h4 class="gradient">Bronze</h4></th>
            <th scope="col"><h4 class="gradient">Silver</h4></th>
            <th scope="col"><h4 class="gradient">Gold</h4></th>
          </tr>
          <tr class="table-start">
            <th scope="row" class="th-noBorder">Quota di rimborso</th>
            <td rowspan="3">
              <p class="bracets">
                <span>&#65373;</span>
                COPERTURA ASSICURATIVA
              </p>
            </td>
            <td><h4 class="gradient">75%</h4></td>
            <td><h4 class="gradient">75%</h4></td>
            <td><h4 class="gradient">75%</h4></td>
          </tr>
          <tr>
            <th scope="row" class="th-noBorder">Massimale di rimborso</th>
            <td><h4 class="gradient">1.000 €</h4></td>
            <td><h4 class="gradient">1.000 €</h4></td>
            <td><h4 class="gradient">1.000 €</h4></td>
          </tr>
          <tr>
            <th scope="row" class="th-noBorder">
              Tutela legale per danni causati o subiti dall’animale
            </th>
            <td>3.000 €</td>
            <td>3.000 €</td>
            <td>3.000 €</td>
          </tr>
          <tr>
            <th scope="row" class="th-noBorder">Quota di rimborso</th>
            <td rowspan="3">
              <p class="bracets">
                <span>&#65373;</span>
                MYVET PROTECTION
              </p>
            </td>
            <td><h4 class="gradient">50%</h4></td>
            <td><h4 class="gradient">50%</h4></td>
            <td><h4 class="gradient">50%</h4></td>
          </tr>
          <tr>
            <th scope="row" class="th-noBorder">Massimale di rimborso</th>
            <td><h4 class="gradient">200 €</h4></td>
            <td><h4 class="gradient">500 €</h4></td>
            <td><h4 class="gradient">800 €</h4></td>
          </tr>
          <tr>
            <th scope="row" class="th-noBorder table-end">
              Pacchetto prevenzione
            </th>
            <td>30 €</td>
            <td>50 €</td>
            <td>100 €</td>
          </tr>
        </table>
      </div>

      <div class="buttons">
        <button routerLink="/chiamatemi" class="empty">Contattaci</button>
        <button routerLink="/registration/1">Preventivo gratuito</button>
      </div>
    </div>
    <div id="row-3">
      <h2 class="gradient">
        Proteggi a pieno la salute del tuo amico a quattro zampe con il nostro
        pacchetto Gold
      </h2>
      <div class="grey-block">
        <div class="testo">
          <h4>Quali sono le spese imprevedibili rimborsate?</h4>
          <p>
            Le polizze sanitarie per cani MyVet sono All Care (infortunio e
            malattia) e rimborsano dal 75% delle spese imprevedibili, fino a
            1800€ all’anno a seconda della formula scelta
          </p>
        </div>
        <div class="grid-container">
          <div class="grid-item">
            <img src="../../assets/svg/showcase/vet-visit.svg" alt="" />
            <h5 class="gradient">Visite veterinarie</h5>
          </div>
          <div class="grid-item">
            <img src="../../assets/svg/showcase/interventi.svg" alt="" />
            <h5 class="gradient">Interventi chirurgici</h5>
          </div>
          <div class="grid-item">
            <img src="../../assets/svg/showcase/ricoveri.svg" alt="" />
            <h5 class="gradient">Ricoveri</h5>
          </div>
          <div class="grid-item">
            <img src="../../assets/svg/showcase/radiografie.svg" alt="" />
            <h5 class="gradient">Eco e radiografie</h5>
          </div>
          <div class="grid-item">
            <img src="../../assets/svg/showcase/med-alternativa.svg" alt="" />
            <h5 class="gradient">Medicina alternativa</h5>
          </div>
          <div class="grid-item">
            <img src="../../assets/svg/showcase/farmaci.svg" alt="" />
            <h5 class="gradient">Farmaci</h5>
          </div>
        </div>
      </div>

      <div class="grey-block">
        <div class="testo">
          <h4>Quali sono le spese prevedibili rimborsate?</h4>
          <p style="color: #313131">
            Tutti i pacchetti di assicurazione sanitaria MyVet includono anche
            un Pacchetto Prevenzione, che rimborsa fino a 100€/anno per i
            trattamenti di prevenzione
          </p>
        </div>

        <div class="testo gradient">
          Vaccini, antiparassitari microcippatura... Tante cure indispensabili
          per preservare la salute del tuo cucciolone e, in parte, rimborsate
          dal Pacchetto Prevenzione annuale delle formule di assicurazione
          sanitaria MyVet
        </div>
      </div>

      <div class="buttons">
        <button class="empty" routerLink="/chiamatemi">Contattaci</button>
        <button routerLink="/registration/1">Preventivo gratuito</button>
      </div>
    </div>
    <div id="row-4">
      <div>
        <h2 style="color: white">Il rimborso è facile con Noi</h2>
        <p style="color: white; margin-top: 16px">
          Qualunque sia la polizza sanitaria che scegli, scopri come ottenere il
          rimborso delle spese sanitarie in pochi semplici passaggi
        </p>
      </div>
    </div>

    <div id="row-5">
      <section class="faq">
        <app-faq [faqs]="questions"></app-faq>
      </section>
    </div>
  </div>
</main>

<main *ngIf="type === 'gatti'" id="gatti">
  <div id="row-1">
    <div class="gatto-container">
      <h1 class="gradient">
        In arrivo a <br />
        Maggio 2024
      </h1>

      <img src="../../assets/img/showcase/gatto.png" alt="" class="gatto-img" />
    </div>

    <div class="grey-block">
      <img src="../../assets/img/showcase/cane.png" alt="" />
      <div class="right-text">
        <h2 class="gradient">
          Nel frattempo scopri i nostri pacchetti per cani
        </h2>
        <div class="buttons">
          <button class="empty" routerLink="/chiamatemi">Contattaci</button>
          <button [routerLink]="['/showcase']" [queryParams]="{ type: 'cani' }">
            Scopri ora
          </button>
        </div>
      </div>
    </div>
  </div>
</main>

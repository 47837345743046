<main>
  <aside>
    <h3 class="invert">Accedi al tuo account MyVet</h3>
    <p class="invert">
      Semplifica il tuo lavoro, tutto in un'unica piattaforma
    </p>
  </aside>

  <div>
    <div class="login-form">
      <app-input
        type="username"
        placeholder="MVTvt001000001"
        label="Username"
        name="username"
        autocomplete="username"
        [autofocus]="true"
        [(ngModel)]="login.username"
      ></app-input>

      <app-input
        type="password"
        label="Password"
        name="password"
        autocomplete="current-password"
        [errorText]="loginErrors.password"
        [(ngModel)]="login.password"
      ></app-input>

      <button (click)="tryLogIn()">
        <h4 class="invert">Continua</h4>
      </button>

      <button class="empty" routerLink="/registration">
        <h4>Registrati</h4>
      </button>
    </div>
  </div>
</main>

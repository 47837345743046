import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-general-conditions',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './general-conditions.component.html',
  styleUrl: './general-conditions.component.scss'
})
export class GeneralConditionsComponent {

  async ngOnInit(){
    window.scrollTo({top: 0});
  }
}

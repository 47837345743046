<div [class]="style">
  <ng-container *ngFor="let option of options">
    <input
      type="radio"
      name="radio"
      [value]="option.value"
      [id]="option.name"
      [checked]="valoreInterno === option.value"
      (change)="onValoreInternoChange(option.value)"
      (blur)="onTouched()"
    />
    <label [for]="option.name"> {{ option.label }} </label>
  </ng-container>
</div>
